@charset "utf-8";
// COLORS
// $primary: #00d1b2;
// $info: #209CEE;
// $success: #23D160;
// $warning: #FFDD57;
// $danger: #FF3860;
// $light: #F5F5F5;
// $dark: #363636;
// TEXT
// $text: #4A4A4A;
// $link: #3273DC;
$body-family: gilroy-semibold;
// $title-family: true;
// $button-family: false;
// BREAKPOINTS
// $gap: 32px;
// $tablet: 769px;
// $desktop: 960px + (2 * $gap);
// $widescreen: 1152px + (2 * $gap);
// $fullhd: 1344px + (2 * $gap);
// $widescreen-enabled: true;
// $fullhd-enabled: false;
// LAYOUT
// $section-padding: 3rem 1.5rem;
// $section-padding-medium: 6rem 1.5rem;
// $section-padding-large: 9rem 1.5rem;
// SEE DOCS FOR MORE:
// https://bit.ly/30UvE5O
// IMPORT BULMA
@import "~bulma/bulma.sass";
// IMPORT FONT AWESOME
@import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");
// Embtel Style.css
@import "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700";
@import "../fonts/gilroy/fonts.css";
@import "../vendor/bootstrap/css/bootstrap.min.css";
@import "framework.css";
@import "../vendor/mega-menu/assets/css/menu.css";
// @import "../fonts/font-awesome/css/font-awesome.min.css";
@import "../vendor/cubeportfolio/css/cubeportfolio.min.css";
@import "../vendor/owl-carousel/owl.carousel.css";
@import "../vendor/owl-carousel/owl.theme.css";
@import "../vendor/owl-carousel/animate.css";
@import "../vendor/aos-next/dist/aos.css";
@import "../vendor/fancybox/dist/jquery.fancybox.min.css";
@import "../vendor/sanzzy-map/dist/snazzy-info-window.min.css";
@import "../fonts/icon/font/flaticon.css";
@import "custom-animation.css";
@import "../vendor/animated-headline-master/style.css";
@import "../vendor/iziModal-master/iziModal.css";
@import "../vendor/jquery-ui/jquery-ui.min.css";
@import "../vendor/selectize.js/selectize.css";
@import "../vendor/tabs/tabs.css";
@import "shortcode-style.css";
// @font-face {
// 	font-family: font-awesome;
// 	src: url('../../../fonts/font-awesome/fonts/fontawesome-webfont.ttf');
//   src: url('../../../fonts/font-awesome/fonts/fontawesome-webfont.eot'),
//   url('../../../fonts/font-awesome/fonts/fontawesome-webfont.woff2') format('woff2'),
//   url('../../../fonts/font-awesome/fonts/fontawesome-webfont.woff') format('woff'),
//   url('../../../fonts/font-awesome/fonts/fontawesome-webfont.svg') format('svg');
// }
// @keyframes "letters-loading" {
// 	0%,100%,75% {
// 		opacity: 0;
// 		transform: rotateY(-90deg);
// 	}
// 	25%,50% {
// 		opacity: 1;
// 		transform: rotateY(0);
// 	}
// }
// &::-webkit-scrollbar {
// 	width: 5px;
// }
// &::-webkit-scrollbar-track {
// 	background: #fff;
// }
// &::-webkit-scrollbar-thumb {
// 	background: var(--rose-gr-bg);
// 	border-radius: 30px;
// }
// &:root {
// 	--text-color: #8a919c;
// 	--text-light: #c0c0c9;
// 	--text-dark: #313131;
// 	--heading: #2A2A2A;
// 	--p-rose: #ff5284;
// 	--rose-gr-bg: linear-gradient( -90deg, rgb(255,182,139) 0%, rgb(255,96,139) 100%);
// 	--rose-gr-reverse: linear-gradient( -90deg, rgb(255,96,139) 0%, rgb(255,182,139) 100%);
// 	--p-green: #0de49d;
// 	--p-orange: #ff8618;
// 	--cyan: #1aceff;
// 	--redone: #ff6262;
// }
:root {
	// --p-green: #f73c82;
	--p-green: #106ad8;
	--text-color: #8a919c;
	--text-light: #c0c0c9;
	--text-dark: #313131;
	--heading: #2A2A2A;
  }
body {
	font-family: gilroy-semibold !important;
	font-weight: 400;
	font-size: 17px;
	position: relative;
	line-height: 28px;
	color: var(--text-color);
	.theme-btn {
		position: relative;
		z-index: 1;
		line-height: 48px;
		border-radius: 30px;
		min-width: 180px;
		font-size: 19px;
		text-align: center;
		padding: 0 35px;
		text-transform: capitalize;
		transition: all .3s ease-in-out;
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			border-radius: 30px;
			transition: all .3s ease-in-out;
			transform: scale(0,1);
			z-index: -1;
		}
		&:hover {
			&:before {
				transform: scale(1);
			}
		}
		i {
			margin-left: 5px;
		}
	}
	.theme-btn.radius3 {
		border-radius: 3px;
		&:before {
			border-radius: 3px;
		}
	}
	.solid-button-one {
		color: #fff;
		background: var(--p-green);
		border: 2px solid var(--p-green);
		&:before {
			background: #fff;
		}
		&:hover {
			color: var(--p-green);
		}
	}
	.solid-button-one.button-rose {
		background: var(--rose-gr-bg);
		border: none;
		line-height: 52px;
		&:before {
			background: var(--rose-gr-reverse);
			transform: scale(1);
			opacity: 0;
		}
		&:hover {
			color: #fff;
			&:before {
				opacity: 1;
			}
		}
	}
	.solid-button-one.button-orange {
		background: var(--p-orange);
		border: 2px solid var(--p-orange);
		&:before {
			background: #fff;
		}
		&:hover {
			color: var(--p-orange);
		}
	}
	.line-button-one {
		color: var(--p-green);
		border: 2px solid var(--p-green);
		&:before {
			background: var(--p-green);
		}
		&:hover {
			color: #fff;
		}
	}
	.line-button-one.button-rose {
		color: var(--p-rose);
		border: 2px solid var(--p-rose);
		&:before {
			background: var(--p-rose);
		}
		&:hover {
			color: #fff;
		}
	}
	.line-button-one.button-orange {
		color: var(--p-orange);
		border: 2px solid var(--p-orange);
		&:before {
			background: var(--p-orange);
		}
		&:hover {
			color: #fff;
		}
	}
	.line-button-two {
		border: 2px solid #dfdfdf;
		color: var(--text-dark);
		&:hover {
			color: #fff;
			border-color: #fff;
		}
		&:before {
			background: var(--rose-gr-bg);
		}
	}
	.line-button-two.p-green {
		&:before {
			background: var(--p-green);
		}
	}
	.line-button-two.button-orange {
		&:before {
			background: var(--p-orange);
		}
	}
	.video-button-one {
		font-size: 18px;
		line-height: 52px;
		color: var(--text-dark);
		font-weight: 500;
		transition: all .3s ease-in-out;
		i {
			font-size: 50px;
			color: var(--text-light);
			vertical-align: middle;
			transition: all .3s ease-in-out;
		}
		&:hover {
			color: var(--p-green);
			i {
				color: var(--p-green);
			}
		}
	}
	.video-button-one.button-rose {
		&:hover {
			color: var(--p-rose);
			i {
				color: var(--p-rose);
			}
		}
	}
	.video-button-one.button-orange {
		&:hover {
			color: var(--p-orange);
			i {
				color: var(--p-orange);
			}
		}
	}
	.theme-button-three {
		text-transform: capitalize;
		text-align: center;
		font-size: 18px;
		color: #fff;
		line-height: 50px;
		padding: 0 40px;
		position: relative;
		z-index: 1;
		background: #212121;
		transition: all .3s ease-in-out;
		&:hover {
			background: var(--p-rose);
		}
	}
	.inline-button-one {
		font-family: gilroy-bold !important;
		font-size: 14px;
		color: var(--text-dark);
		text-transform: uppercase;
		letter-spacing: 1.2px;
		text-decoration: underline;
		&:hover {
			color: var(--p-rose);
		}
	}
	.shadow-button-one {
		box-shadow: 0 10px 25px 0 rgba(123,147,171,.15);
		color: var(--p-rose);
		line-height: 50px;
		background: #fff;
		&:hover {
			background: var(--p-rose);
			color: #fff;
		}
	}
}
.ctn-preloader {
	align-items: center;
	-webkit-align-items: center;
	display: flex;
	display: -ms-flexbox;
	height: 100%;
	justify-content: center;
	-webkit-justify-content: center;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 100;
	background: #fff;
	.animation-preloader {
		position: absolute;
		z-index: 100;
		.icon {
			margin-bottom: 60px;
			img {
				margin: 0 auto;
			}
		}
		.txt-loading {
			text-align: center;
			user-select: none;
			.letters-loading {
				&:before {
					animation: letters-loading 4s infinite;
					color: var(--p-rose);
					content: attr(data-text-preloader);
					left: 0;
					opacity: 0;
					top: 0;
					line-height: 70px;
					position: absolute;
				}
				font-weight: 700;
				letter-spacing: 15px;
				display: inline-block;
				color: rgba(255,82,132,.15);
				position: relative;
				font-size: 70px;
				line-height: 70px;
				&:nth-child(2) {
					&:before {
						animation-delay: .2s;
					}
				}
				&:nth-child(3) {
					&:before {
						animation-delay: .4s;
					}
				}
				&:nth-child(4) {
					&:before {
						animation-delay: .6s;
					}
				}
				&:nth-child(5) {
					&:before {
						animation-delay: .8s;
					}
				}
				&:nth-child(6) {
					&:before {
						animation-delay: 1s;
					}
				}
				&:nth-child(7) {
					&:before {
						animation-delay: 1.2s;
					}
				}
				&:nth-child(8) {
					&:before {
						animation-delay: 1.4s;
					}
				}
			}
		}
	}
	.loader-section {
		background-color: #fff;
		height: 100%;
		position: fixed;
		top: 0;
		width: calc(50% + 1px);
	}
}
.loaded {
	.animation-preloader {
		opacity: 0;
		transition: .3s ease-out;
	}
}
.scroll-top {
	width: 25px;
	height: 25px;
	position: fixed;
	bottom: 10px;
	right: 5px;
	z-index: 99;
	text-align: center;
	color: #fff;
	font-size: 18px;
	display: none;
	cursor: pointer;
	border-radius: 3px;
	background: var(--rose-gr-bg);
	&:after {
		position: absolute;
		z-index: -1;
		content: '';
		top: 100%;
		left: 5%;
		height: 10px;
		width: 90%;
		opacity: 1;
		background: radial-gradient(ellipse at center,rgba(0,0,0,.25) 0,rgba(0,0,0,0) 80%);
	}
}
.main-page-wrapper {
	overflow-x: hidden;
}
.h1 {
	@media(max-width:767px){
		font-size: 40px !important;
		line-height: 50px !important;
	}
	color: var(--heading);
	font-weight: 400;
	font-size: 62px !important;
	line-height: 70px !important;
	font-family: gilroy-bold !important;
}
.h2 {
	@media(max-width:767px){
		font-size: 35px !important;
		line-height: 45px !important;
	}
	color: var(--heading);
	font-weight: 400;
	font-size: 50px !important;
	line-height: 65px !important;
	font-family: gilroy-bold !important;
}
.h3 {
	@media(max-width:767px){
		font-size: 27px !important;
	}
	color: var(--heading);
	font-weight: 400;
	font-size: 42px !important;
	font-family: gilroy-bold !important;
}
.h4 {
	@media(max-width:767px){
		font-size: 20px !important;
	}
	color: var(--heading);
	font-weight: 400;
	font-size: 28px !important;
	font-family: gilroy-bold !important;
}
.h5 {
	@media(max-width:767px){
		font-size: 18px !important;
	}
	color: var(--heading);
	font-weight: 400;
	font-size: 24px !important;
	font-family: gilroy-semibold !important;
}
.h6 {
	color: var(--heading);
	font-weight: 400;
	font-size: 20px !important;
}
h1 {
	@media(max-width:767px){
		font-size: 40px !important;
		line-height: 50px !important;
	}
	color: var(--heading);
	font-weight: 400;
	font-size: 62px !important;
	line-height: 70px !important;
	font-family: gilroy-bold !important;
}
h2 {
	@media(max-width:767px){
		font-size: 35px !important;
		line-height: 45px !important;
	}
	color: var(--heading);
	font-weight: 400;
	font-size: 50px !important;
	line-height: 65px !important;
	font-family: gilroy-bold !important;
}
h3 {
	@media(max-width:767px){
		font-size: 27px !important;
	}
	color: var(--heading);
	font-weight: 400;
	font-size: 42px !important;
	font-family: gilroy-bold !important;
}
h4 {
	@media(max-width:767px){
		font-size: 20px !important;
	}
	color: var(--heading);
	font-weight: 400;
	font-size: 28px !important;
	font-family: gilroy-bold !important;
}
h5 {
	@media(max-width:767px){
		font-size: 18px !important;
	}
	color: var(--heading);
	font-weight: 400;
	font-size: 24px !important;
	font-family: gilroy-semibold;
}
h6 {
	color: var(--heading);
	font-weight: 400;
	font-size: 20px !important;
}
#path-shape-wrapper {
	position: absolute;
	z-index: -9999;
}
.shape-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
}
.img-shape {
	position: absolute;
}
.pos-r {
	position: relative;
}
.pos-a {
	position: absolute;
}
.full-width-container {
	padding-left: 60px;
	padding-right: 60px;
}
.blog-details {
	.post-tag-area {
		.share-icon {
			li {
				a {
					transition: all .3s ease-in-out;
					font-size: 20px;
					color: #d3d3d3;
					margin-left: 13px;
					&:hover {
						color: var(--p-rose);
					}
				}
				font-family: gilroy-semibold;
				display: inline-block;
				font-size: 20px;
				color: #3e3e3e;
			}
		}
		.tags {
			li {
				display: inline-block;
				font-size: 20px;
				color: #3e3e3e;
				&:first-child {
					font-family: gilroy-semibold;
				}
				a {
					font-size: 18px;
					color: #989ca2;
					&:hover {
						color: #3e3e3e;
					}
				}
			}
		}
		ul {
			padding-bottom: 20px;
		}
		border-bottom: 1px solid #e5e5e5;
		padding-bottom: 10px;
	}
	.user-comment-area {
		.single-comment {
			.reply {
				transition: all .3s ease-in-out;
				width: 70px;
				height: 30px;
				border: 2px solid #e7e7e7;
				border-radius: 3px;
				font-size: 14px;
				text-transform: uppercase;
				color: #3e3e3e;
				position: absolute;
				top: 0;
				right: 0;
				background: 0 0;
				&:hover {
					color: #fff;
					background: #212121;
				}
			}
			.user-comment-data {
				.date {
					font-style: italic;
					color: #989ca2;
				}
			}
			padding: 50px 0 40px !important;
			border-bottom: 1px solid #e5e5e5;
		}
		.single-comment.comment-reply {
			margin-left: 100px;
		}
	}
	.post-data {
		padding: 40px 0 60px;
		.title {
			font-family: gilroy-bold !important;
			font-size: 28px;
			padding: 15px 0 20px;
		}
		p {
			padding-bottom: 30px;
		}
		blockquote {
			font-family: gilroy-semibold;
			font-size: 32px;
			line-height: 45px;
			margin: 42px 0 60px;
			color: var(--text-dark);
			position: relative;
			padding-left: 90px;
			&:before {
				content: '';
				width: 60px;
				height: 3px;
				background: #3e3e3e;
				position: absolute;
				top: 17px;
				left: 0;
			}
		}
		h5 {
			font-size: 20px;
			line-height: 32px;
			color: var(--text-dark);
			padding-bottom: 30px;
		}
		strong {
			font-family: gilroy-semibold;
			display: inline-block;
			font-size: 23px;
			line-height: 32px;
			font-style: italic;
			margin: 0 0 45px;
			color: var(--text-dark);
			position: relative;
			padding-right: 90px;
			span {
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;
				width: 100px;
				height: 2px;
				background: #535353;
			}
		}
	}
	.inner-block-title {
		font-size: 36px;
		color: #3e3e3e;
		padding-bottom: 28px;
	}
	.comment-form-area {
		form {
			padding-top: 55px;
		}
	}
	.blog-hero-banner {
		padding: 480px 0 220px;
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
		animation: imageBgAnim 50s infinite linear alternate;
		.date {
			font-size: 17px;
			color: #fff;
			margin-bottom: 20px;
		}
		.blog-title {
			font-family: gilroy-bold !important;
			font-size: 48px;
			line-height: 55px;
			color: #fff;
			width: 70%;
		}
	}
	.blog-custom-container {
		max-width: 980px;
		padding: 0 15px;
		margin: 0 auto;
	}
}
.blog-details-fg {
	.blog-fg-data {
		.video-banner-blog {
			.video-button {
				transition: all .3s ease-in-out;
				display: block;
				width: 90px;
				height: 90px;
				background: #fff;
				border-radius: 50%;
				text-align: center;
				line-height: 90px;
				color: #000;
				font-size: 40px;
				position: absolute;
				top: 50%;
				left: 50%;
				padding-left: 10px;
				transform: translate(-50% ,-50%);
				&:hover {
					color: #fff;
					background: var(--p-rose);
				}
			}
			background: url('../images/blog/bg.jpg') no-repeat center;
			background-size: cover;
			background-attachment: fixed;
			height: 520px;
			position: relative;
			margin: 100px 0 105px;
		}
		.post-data {
			padding: 0;
			h4 {
				font-size: 28px;
				padding: 30px 0 25px;
			}
		}
		position: relative;
		z-index: 1;
		margin-top: -150px;
		.custom-container-bg {
			padding: 0 75px 0;
			background: #fff;
		}
		.blog-img-gallery {
			padding: 50px 0 80px;
			img {
				width: 100%;
			}
		}
	}
	.comment-form-area {
		.custom-container-bg {
			background: 0 0;
		}
	}
}
.blog-post-block-three {
	.post {
		.post-info {
			li {
				a {
					transition: all .3s ease-in-out;
					font-size: 18px;
					color: #fff;
					&:hover {
						color: #000;
					}
				}
				display: inline-block;
			}
		}
		.read-more {
			transition: all .3s ease-in-out;
			font-family: gilroy-bold !important;
			font-size: 14px;
			color: #fff;
			text-transform: uppercase;
			letter-spacing: 1.2px;
			line-height: 51px;
			border: 1px solid #fff;
			width: 210px;
			text-align: center;
			&:hover {
				background: var(--p-rose);
				border-color: var(--p-rose);
			}
		}
		h2 {
			a {
				font-size: 58px;
				line-height: 64px;
				color: #fff;
				margin: 13px 0 40px;
			}
		}
	}
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-position: center top;
	.opacity {
		background: rgba(0,0,0,.25);
		padding: 215px 0 220px;
	}
}
.blog-post-block-two {
	.post {
		.post-info {
			li {
				a {
					transition: all .3s ease-in-out;
					font-size: 16px;
					color: var(--text-light);
					&:hover {
						color: var(--text-dark);
					}
				}
				display: inline-block;
			}
		}
		h4 {
			a {
				transition: all .3s ease-in-out;
				font-size: 28px;
				color: var(--heading);
				margin: 5px 0 20px;
			}
		}
		.read-more {
			margin-top: 20px;
		}
	}
	.img-holder {
		img {
			transition: all .5s ease-in-out;
			width: 100%;
		}
		position: relative;
		background: #212121;
		overflow: hidden;
		margin-bottom: 30px;
	}
	&:hover {
		.img-holder {
			img {
				transform: scale3d(1.1,1.1,1);
				opacity: .6;
			}
		}
		.post {
			h4 {
				a {
					color: var(--p-rose);
				}
			}
		}
	}
}
.blog-sidebar {
	.list-item {
		ul {
			li {
				a {
					transition: all .3s ease-in-out;
					font-size: 17px;
					line-height: 40px;
					color: var(--text-color);
					text-transform: capitalize;
					&:hover {
						color: var(--p-rose);
					}
				}
			}
		}
	}
	.sidebar-keyword {
		ul {
			li {
				a {
					transition: all .3s ease-in-out;
					display: block;
					line-height: 35px;
					font-size: 14px;
					text-transform: uppercase;
					color: var(--text-color);
					border: 1px solid #e7e7e7;
					padding: 0 25px;
					&:hover {
						background: var(--p-rose);
						border-color: var(--p-rose);
						color: #fff;
					}
				}
				float: left;
				padding: 0 5px;
				margin-top: 15px;
			}
			margin: 0 -5px;
		}
	}
	.sidebar-recent-news {
		.text {
			a {
				transition: all .3s ease-in-out;
				font-family: gilroy-semibold;
				font-size: 20px;
				line-height: 26px;
				color: var(--text-dark);
				margin-bottom: 10px;
			}
			padding-left: 25px;
			span {
				display: block;
				color: var(--text-light);
				font-size: 16px;
			}
		}
		img {
			width: 80px;
		}
		li {
			margin-top: 20px;
			padding-bottom: 20px;
			&:hover {
				.text {
					a {
						color: var(--p-rose);
					}
				}
			}
		}
	}
	.sidebar-search {
		button {
			transition: all .3s ease-in-out;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 60px;
			font-size: 20px;
			background: 0 0;
			color: var(--text-color);
			&:hover {
				color: var(--p-rose);
			}
		}
		height: 60px;
		position: relative;
		input {
			width: 100%;
			height: 100%;
			border: 1px solid rgba(0,0,0,.06);
			padding: 0 60px 0 20px;
		}
	}
	.sidebar-title {
		font-family: gilroy-bold !important;
		font-size: 24px;
		text-transform: uppercase;
		letter-spacing: 1.2px;
		padding-bottom: 18px;
	}
}
.carousel-control-next {
	transition: all .3s ease-in-out;
}
.carousel-control-prev {
	transition: all .3s ease-in-out;
}
.contact-address-two {
	.address-block {
		a {
			transition: all .3s ease-in-out;
		}
		padding-bottom: 40px;
		text-align: center;
		.icon-box {
			display: inline-block;
			height: 70px;
		}
		h5 {
			font-size: 24px;
			padding: 18px 0 20px;
		}
		p {
			color: var(--text-color);
			a {
				color: var(--text-color);
				&:hover {
					color: var(--p-rose);
				}
			}
		}
		ul {
			li {
				display: inline-block;
				margin: 10px 8px 0;
				a {
					font-size: 18px;
					color: var(--text-color);
					&:hover {
						color: var(--p-rose);
					}
				}
			}
		}
	}
}
.contact-home-one {
	.contact-text {
		.call-to-dial {
			transition: all .3s ease-in-out;
			font-size: 26px;
			color: var(--text-dark);
			&:hover {
				color: var(--p-rose);
			}
		}
		p {
			font-size: 20px;
			position: relative;
			padding: 75px 0 15px;
			&:before {
				content: '';
				position: absolute;
				width: 60px;
				height: 5px;
				border-radius: 5px;
				background: var(--p-rose);
				left: 0;
				top: 20px;
			}
		}
	}
	.shape-one {
		width: 890px;
		height: 890px;
		top: 70px;
		right: -245px;
		z-index: -1;
	}
	.shape-two {
		width: 890px;
		height: 890px;
		top: 0;
		right: -265px;
	}
	.shape-three {
		width: 17px;
		height: 17px;
		top: 60px;
		left: 25%;
	}
	.shape-four {
		width: 11px;
		height: 11px;
		top: 50%;
		left: 5%;
	}
	.shape-five {
		width: 20px;
		height: 20px;
		bottom: 80px;
		left: 37%;
	}
	.shape-six {
		top: 0;
		left: 0;
	}
	.theme-form-style-two {
		margin-left: 60px;
	}
}
.eCommerce-side-menu {
	.main-menu-list {
		ul {
			li {
				a {
					transition: all .3s ease-in-out;
				}
			}
		}
		>ul {
			margin-top: 50%;
			padding-bottom: 30px;
			>li {
				>a {
					font-size: 35px;
					line-height: 65px;
					color: var(--text-dark);
					position: relative;
					display: block;
					.expander {
						position: absolute;
						right: 0;
						top: 0;
						background: 0 0;
						font-size: 15px;
						line-height: 65px;
						color: inherit;
					}
				}
				opacity: 0;
				transform: translateX(50%);
				transition: all .7s ease-in-out;
			}
			li {
				&:hover {
					>a {
						color: var(--p-rose);
					}
				}
			}
			.sub-menu {
				padding: 10px 0 10px 30px;
				a {
					font-size: 22px;
					line-height: 40px;
					color: var(--text-color);
				}
			}
		}
	}
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	width: 360px;
	background: #fff;
	box-shadow: 0 2px 100px 0 rgba(218,218,218,.5);
	z-index: 999;
	padding: 0 25px 30px 80px;
	opacity: 0;
	transform: scale(0 ,1);
	transform-origin: 100% 0;
	transition: all .5s ease-in-out;
	max-height: 100vh;
	overflow-y: auto;
	.logo-wrapper {
		margin-top: 60px;
		.close-button {
			position: absolute;
			top: 10px;
			left: 10px;
			background: 0 0;
			height: 36px;
		}
	}
	.copy-right {
		font-size: 16px;
		color: rgba(62,62,62,.7);
		margin-top: 100px;
	}
}
.feature-block-four {
	.icon-box {
		transition: all .3s ease-in-out;
		width: 100px;
		height: 100px;
		position: relative;
		margin: 0 auto;
		border-radius: 50%;
		img {
			margin: 0 auto;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.more {
		transition: all .3s ease-in-out;
		font-size: 32px;
		color: var(--text-light);
	}
	h5 {
		a {
			color: var(--heading);
			line-height: 30px;
		}
	}
	&:hover {
		.more {
			color: var(--p-green);
		}
	}
	padding: 0 50px;
}
.feature-block-one.hover-state {
	&:after {
		transition: all .3s ease-in-out;
		content: '';
		position: absolute;
		width: 200px;
		height: 200px;
		border-radius: 50%;
		background: rgba(255,255,255,.13);
		z-index: -1;
		top: -80px;
		right: -80px;
		opacity: 0;
		transform: scale(.2);
	}
	&:before {
		transition: all .3s ease-in-out;
		content: '';
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(130deg,#ffad33 0,#ff5ee1 100%);
		opacity: 0;
	}
	&:hover {
		&:before {
			opacity: 1;
		}
		&:after {
			opacity: 1;
			transform: scale(1);
			transition-duration: 1s;
		}
		.icon {
			color: #fff;
		}
		.title {
			color: #fff;
		}
		p {
			color: #fff;
		}
	}
}
.footer-about-widget {
	.email {
		transition: all .3s ease-in-out;
		font-size: 18px;
		color: var(--text-color);
		margin: 12px 0 10px;
		display: block;
		&:hover {
			color: var(--p-green);
		}
	}
	.phone {
		transition: all .3s ease-in-out;
		font-size: 20px;
		color: var(--heading);
		&:hover {
			color: var(--p-green);
		}
	}
}
.footer-information {
	ul {
		li {
			a {
				transition: all .3s ease-in-out;
				width: 40px;
				height: 40px;
				border: 1px solid #dbdbdb;
				border-radius: 50%;
				text-align: center;
				line-height: 40px;
				color: #dbdbdb;
				margin-right: 10px;
				&:hover {
					color: #fff;
					background: var(--p-green);
				}
			}
			display: inline-block;
		}
	}
	p {
		padding: 0 0 30px;
		color: #76797e;
	}
}
.footer-list {
	ul {
		li {
			a {
				transition: all .3s ease-in-out;
				display: block;
				line-height: 38px;
				font-size: 17px;
				color: var(--text-color);
				&:hover {
					color: var(--p-green);
				}
			}
		}
	}
}
.gallery-polar-state {
	.img-holder {
		.icon {
			transition: all .3s ease-in-out;
			font-family: gilroy-ultralight;
			font-size: 80px;
			position: absolute;
			top: 50%;
			right: 50%;
			transform: translate(50% ,-50%);
			color: #fff;
			z-index: 1;
			opacity: 0;
		}
		background: var(--rose-gr-bg);
		overflow: hidden;
		img {
			width: 100%;
			transition: all .6s ease-in-out;
		}
	}
	&:hover {
		.img-holder {
			img {
				transform: scale(.93);
				opacity: .5;
			}
		}
		.icon {
			opacity: 1;
		}
	}
}
.gallery-sidebar {
	.share-option {
		ul {
			li {
				a {
					transition: all .3s ease-in-out;
					font-size: 20px;
					color: var(--text-dark);
					margin-right: 18px;
					&:hover {
						color: var(--p-rose);
					}
				}
				display: inline-block;
			}
		}
	}
	width: 265px;
	.title {
		font-family: gilroy-bold !important;
		font-size: 28px;
		position: relative;
		padding-bottom: 25px;
		margin-bottom: 50px;
		&:before {
			content: '';
			position: absolute;
			width: 30px;
			height: 3px;
			background: var(--p-rose);
			bottom: 0;
			left: 0;
		}
	}
	.cbp-l-filters-alignCenter.filter-menu {
		margin-bottom: 95px;
		text-align: left;
		.cbp-filter-item {
			font-family: Roboto,sans-serif;
			font-size: 18px;
			font-weight: 400;
			line-height: 40px;
			display: block;
			padding: 0;
			margin: 0;
			text-transform: capitalize;
			border: none;
		}
	}
}
.header-search {
	.icon {
		transition: all .3s ease-in-out;
	}
}
.inline-button-one {
	transition: all .3s ease-in-out;
}
.modal-content {
	.btn {
		transition: all .3s ease-in-out;
	}
}
.nav-tabs {
	>li {
		>a {
			transition: all .3s ease-in-out;
		}
	}
}
.newsletter-section {
	.main-wrapper {
		form {
			@media(max-width:767px){
				max-width: 100%;
			}
			button {
				transition: all .3s ease-in-out;
				position: absolute;
				top: 0;
				right: 0;
				height: 65px;
				width: 100px;
				border-radius: 0 38px 38px 0;
				color: #fff;
				font-size: 32px;
				background: var(--p-green);
				&:hover {
					background: #262626;
				}
			}
			max-width: 57%;
			margin: 0 auto;
			position: relative;
			input {
				width: 100%;
				height: 65px;
				font-style: italic;
				border: 1px solid #ededed;
				border-radius: 38px;
				padding: 0 105px 0 40px;
				font-size: 18px;
			}
			&::placeholder {
				color: rgba(62,62,62,.4);
				opacity: 1;
			}
			&:-ms-input-placeholder {
				color: rgba(62,62,62,.4);
			}
			&::-ms-input-placeholder {
				color: rgba(62,62,62,.4);
			}
		}
	}
	.subscription-label {
		text-align: center;
		margin: 5px 0 0;
		display: block;
	}
	.subscription-label.error {
		color: #ff4343;
	}
	.subscription-label.valid {
		color: var(--p-green);
	}
}
.owl-theme {
	.owl-nav {
		[class*=owl-] {
			transition: all .3s ease-in-out;
		}
	}
}
.panel {
	.panel-heading {
		.panel-title {
			a {
				transition: all .3s ease-in-out;
				&:before {
					transition: all .3s ease-in-out;
				}
			}
		}
	}
}
.portfolio-full-width-grid {
	.img-content-wrapper {
		.cbp-item-wrapper {
			.text {
				transition: all .3s ease-in-out;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 60px;
				padding: 30px 65px 25px 30px;
				background: #fff;
				transition: all .5s ease-in-out;
				z-index: 1;
				border-bottom: 1px solid #fff;
				.read-more {
					transition: all .3s ease-in-out;
					font-size: 58px;
					font-weight: 100;
					color: var(--text-light);
					position: absolute;
					right: 30px;
					top: 50%;
					transform: translateY(-50%);
				}
				h3 {
					font-family: gilroy-semibold;
					font-size: 24px;
					padding-bottom: 5px;
				}
			}
			padding-bottom: 60px;
			&:hover {
				.text {
					.read-more {
						color: var(--p-rose);
					}
					right: 0;
					border-bottom: 1px solid rgba(0,0,0,.05);
				}
			}
		}
		width: calc(100% - 265px);
	}
	padding: 250px 75px 150px;
}
.pricing-plan-classic {
	.nav-tabs {
		>li {
			>a {
				&:before {
					transition: all .3s ease-in-out;
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background: var(--rose-gr-bg);
					z-index: -1;
					opacity: 0;
				}
				font-family: gilroy-semibold;
				width: 155px;
				line-height: 60px;
				font-size: 16px;
				background: #fff;
				color: var(--text-dark);
				text-align: center;
				text-transform: uppercase;
				letter-spacing: 1px;
				position: relative;
				overflow: hidden;
				z-index: 1;
				box-shadow: 0 10px 30px 0 rgba(156,157,161,.16);
			}
			&:first-child {
				>a {
					border-radius: 32px 0 0 32px;
				}
			}
			&:last-child {
				>a {
					border-radius: 0 32px 32px 0;
				}
			}
			>a.active {
				color: #fff;
				&:focus {
					color: #fff;
					&:before {
						opacity: 1;
					}
				}
				&:hover {
					color: #fff;
					&:before {
						opacity: 1;
					}
				}
				&:before {
					opacity: 1;
				}
			}
		}
		border: none;
	}
	.shape-one {
		width: 15px;
		height: 15px;
		top: 50px;
		left: 30%;
		animation: animationFramesTwo 30s infinite linear;
	}
	.shape-two {
		width: 12px;
		height: 12px;
		top: 21%;
		right: 10%;
		animation: animationFramesOne 25s infinite linear;
	}
	.shape-three {
		width: 8px;
		height: 8px;
		bottom: 17%;
		left: 10%;
		animation: animationFramesTwo 25s infinite linear;
	}
	.shape-four {
		width: 20px;
		height: 20px;
		bottom: 55px;
		left: 43%;
		animation: animationFramesOne 30s infinite linear;
	}
	.tab-content {
		.table-content {
			position: relative;
			.price-table {
				width: 360px;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				transition: all .7s ease;
				transform: scale(.8,.8) translateX(225px);
				pointer-events: none;
				.col-inner {
					position: relative;
					padding: 60px 15px 80px 15px;
					text-align: center;
					background: #fff;
					box-shadow: 0 20px 40px 0 rgba(60,67,113,.05);
					h6 {
						font-family: gilroy-semibold;
						font-size: 18px;
						text-transform: uppercase;
						letter-spacing: 1px;
					}
					.price {
						font-family: gilroy-semibold;
						font-size: 60px;
						color: var(--p-rose);
						background: var(--rose-gr-bg);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						line-height: 80px;
						sup {
							font-family: gilroy-bold !important;
							font-size: 24px;
							color: var(--p-rose);
							background: var(--rose-gr-bg);
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							top: -35px;
						}
					}
					.tag {
						font-size: 24px;
						color: var(--text-dark);
						text-decoration: underline;
					}
					ul {
						li {
							line-height: 42px;
						}
					}
				}
			}
			.price-table.active {
				position: relative;
				z-index: 5;
				transform: scale(1) translateX(0);
				pointer-events: visible;
			}
		}
	}
}
.shop-demo-filter {
	.selectize-dropdown {
		.option {
			transition: all .3s ease-in-out;
			font-size: 15px;
			color: #4f4f4f;
			line-height: 30px;
			&:hover {
				color: #fff;
				background: var(--p-rose);
			}
		}
		background: #fff;
		border: 1px solid #e9e9e9;
		border-top: none;
		box-shadow: 0 50px 100px 0 rgba(229,232,235,.2);
		cursor: pointer;
		.active {
			color: #fff;
			background: var(--p-rose);
		}
	}
	.selectize-input {
		width: 170px;
		line-height: 45px;
		border-radius: 0;
		border: 1px solid #e9e9e9;
		box-shadow: none;
		outline: 0;
		padding: 0 15px;
		.item {
			font-size: 15px;
			color: #4f4f4f;
		}
		input {
			font-size: 15px;
			color: #4f4f4f;
		}
	}
	.selectize-control.single {
		.selectize-input {
			&:after {
				right: 8px;
			}
		}
	}
}
.shop-details {
	.procuct-details {
		.product-info {
			.color-filter {
				li {
					a {
						transition: all .3s ease-in-out;
						display: block;
						margin-right: 10px;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						border-width: 4px;
						border-style: solid;
					}
					display: inline-block;
					&:nth-child(1) {
						a {
							border-color: #9aa2ce;
						}
						&:hover {
							a {
								background: #9aa2ce;
							}
						}
					}
					&:nth-child(2) {
						a {
							border-color: #00ead0;
						}
						&:hover {
							a {
								background: #00ead0;
							}
						}
					}
					&:nth-child(3) {
						a {
							border-color: #ff4b68;
						}
						&:hover {
							a {
								background: #ff4b68;
							}
						}
					}
					&:nth-child(4) {
						a {
							border-color: #ffd657;
						}
						&:hover {
							a {
								background: #ffd657;
							}
						}
					}
				}
			}
			.wishlist-button {
				transition: all .3s ease-in-out;
				font-family: gilroy-semibold;
				width: 190px;
				line-height: 48px;
				font-size: 16px;
				color: rgba(0,0,0,.45);
				text-align: center;
				border: 1px solid #dcdcdc;
				&:hover {
					color: #fff;
					background: #212121;
					border-color: #212121;
				}
				i {
					margin-right: 8px;
					opacity: .8;
				}
			}
			padding-left: 30px;
			.product-name {
				font-size: 32px;
				line-height: 40px;
				padding-bottom: 15px;
				color: #313131;
			}
			.rating {
				li {
					display: inline-block;
					color: #a2a2a2;
					&:nth-child(6) {
						margin: 0 10px;
						font-family: gilroy-semibold;
						color: var(--text-dark);
					}
					i {
						color: #ffbd3a;
					}
				}
			}
			.price {
				display: block;
				font-weight: 400;
				font-size: 32px;
				margin: 35px 0 25px;
				color: var(--text-dark);
			}
			.availability {
				li {
					display: inline-block;
					line-height: 40px;
					font-size: 18px;
					span {
						color: var(--text-dark);
						font-weight: 500;
					}
					&:nth-child(2) {
						margin: 0 10px;
					}
				}
			}
			p {
				font-size: 18px;
				margin: 25px 0 50px 0;
			}
			.customize-order {
				h6 {
					font-family: gilroy-semibold;
					font-size: 20px;
					padding-bottom: 25px;
				}
				margin-bottom: 50px;
			}
			.quantity {
				ul {
					border: 1px solid #e3e3e3;
					display: inline-block;
					li {
						display: inline-block;
						line-height: 40px;
						max-height: 40px;
						button {
							font-size: 25px;
							color: #c9c9c9;
							background: 0 0;
							width: 25px;
						}
					}
					.product-value {
						font-size: 18px;
						color: var(--text-dark);
						font-weight: 500;
						max-width: 42px;
						background: 0 0;
						border: none;
						padding-left: 10px;
					}
				}
				padding-right: 90px;
			}
			.cart-button {
				margin-right: 20px;
				font-family: gilroy-semibold;
				font-size: 16px;
			}
		}
		.tab-content {
			img {
				width: 100%;
			}
			margin-bottom: 40px;
		}
		.nav-tabs {
			>li {
				>a {
					padding: 0;
					margin: 0 20px 0 0;
					border: 0;
					border-radius: 0;
					width: 100px;
					height: 100px;
					display: block;
					overflow: hidden;
				}
			}
		}
	}
	.nav-tabs {
		>li {
			margin: 0;
		}
		border: none;
	}
	.product-review-tab {
		border: 1px solid #ebebeb;
		padding: 40px 55px 35px;
		margin: 125px 0 130px;
		.nav-tabs {
			li {
				a {
					font-family: gilroy-bold !important;
					padding: 0 0 22px 0;
					margin-right: 60px;
					font-size: 16px;
					color: var(--text-light);
					text-transform: uppercase;
					letter-spacing: 1.3px;
					border: none;
					border-radius: 0;
					background: 0 0;
					position: relative;
					&:before {
						content: '';
						position: absolute;
						width: 20px;
						height: 20px;
						background: #fff;
						border-top: 1px solid #ebebeb;
						border-right: 1px solid #ebebeb;
						transform: rotate(135deg);
						bottom: -11px;
						left: 50%;
						margin-left: -10px;
						z-index: 1;
						opacity: 0;
					}
				}
				&:last-child {
					a {
						margin-right: 0;
					}
				}
			}
			border-bottom: 1px solid #ebebeb;
			.nav-link.active {
				color: var(--text-dark);
				&:before {
					opacity: 1;
				}
			}
		}
		.tab-pane {
			padding: 30px 0 0;
		}
		.tab-content {
			.list-title {
				font-family: gilroy-semibold;
				font-size: 20px;
				padding: 35px 0 22px;
			}
			.tab-title {
				font-size: 20px;
				color: #474747;
				padding: 10px 0 22px;
			}
			.list-item {
				li {
					padding-left: 20px;
					margin-bottom: 15px;
					position: relative;
					&:before {
						content: '';
						position: absolute;
						width: 6px;
						height: 6px;
						border-radius: 50%;
						left: 0;
						top: 10px;
						background: #212121;
					}
				}
			}
		}
	}
	.user-comment-area {
		padding-right: 200px;
	}
}
.team-minimal {
	.single-team-member {
		.hover-content {
			transition: all .3s ease-in-out;
			position: absolute;
			top: 0;
			right: 0;
			border-radius: 50%;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,.8);
			transform: scale(.7);
			opacity: 0;
			ul {
				li {
					a {
						transition: all .3s ease-in-out;
						font-size: 25px;
						color: #fff;
						&:hover {
							color: var(--p-rose);
						}
					}
					display: inline-block;
					margin: 0 12px;
				}
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		text-align: center;
		margin-bottom: 130px;
		.img-box {
			width: 300px;
			height: 300px;
			border-radius: 50%;
			overflow: hidden;
			margin: 0 auto;
			position: relative;
		}
		.info-meta {
			padding: 30px 0 0;
		}
		&:hover {
			.hover-content {
				transform: scale(1);
				opacity: 1;
			}
		}
	}
}
.team-standard {
	.single-team-member {
		.hover-content {
			transition: all .3s ease-in-out;
			position: absolute;
			width: 45px;
			background: #fff;
			box-shadow: 8px 0 8.6px 1.4px rgba(229,234,239,.2);
			right: 0;
			top: 0;
			bottom: 0;
			transform: scale(0,1);
			transform-origin: 100% 0;
			ul {
				li {
					a {
						transition: all .3s ease-in-out;
						display: block;
						text-align: center;
						color: rgba(0,0,0,.4);
						font-size: 20px;
						margin: 15px 0;
						&:hover {
							color: var(--p-rose);
						}
					}
				}
				position: relative;
				top: 40%;
				transform: translateY(-50%);
			}
		}
		position: relative;
		margin-bottom: 60px;
		.img-box {
			img {
				border-radius: 4px 4px 0 0;
			}
		}
		.info-meta {
			padding: 25px 0 30px 50px;
			background: #fff;
			box-shadow: 5.994px 14.835px 30px 0 rgba(229,234,239,.5);
		}
		&:hover {
			.hover-content {
				transform: scale(1,1);
			}
		}
	}
}
.theme-breadcrumbs {
	.page-breadcrumbs {
		li {
			a {
				transition: all .3s ease-in-out;
			}
		}
	}
}
.theme-footer-three {
	a {
		transition: all .3s ease-in-out;
	}
}
.theme-footer-two {
	.footer-menu {
		li {
			a {
				transition: all .3s ease-in-out;
			}
		}
	}
	.social-icon {
		li {
			a {
				transition: all .3s ease-in-out;
			}
		}
	}
}
.theme-pagination-one {
	ul {
		li {
			a {
				transition: all .3s ease-in-out;
				font-family: gilroy-semibold;
				font-size: 22px;
				color: var(--text-dark);
				line-height: 40px;
				.icon {
					font-size: 30px;
				}
				&:hover {
					color: var(--p-rose);
				}
			}
			float: left;
			padding: 0 10px;
		}
		display: inline-block;
		margin: 0 -10px;
		li.active {
			a {
				cursor: default;
				color: var(--p-rose);
			}
		}
	}
}
.theme-pagination-two {
	.theme-pager {
		span {
			transition: all .3s ease-in-out;
			font-size: 45px;
			position: absolute;
			color: var(--text-dark);
			bottom: -10px;
		}
		strong {
			transition: all .3s ease-in-out;
			font-family: gilroy-bold !important;
			font-size: 28px;
			padding-top: 10px;
			display: block;
			color: var(--text-dark);
			margin: 0;
		}
		position: relative;
		i {
			font-style: normal;
			font-size: 16px;
			color: var(--text-light);
			text-transform: uppercase;
			letter-spacing: 1.5px;
		}
		&:hover {
			span {
				color: var(--p-rose);
			}
			strong {
				color: var(--p-rose);
			}
		}
	}
	.prev {
		padding-left: 75px;
		span {
			left: 0;
		}
	}
	.next {
		padding-right: 75px;
		span {
			right: 0;
		}
	}
}
.theme-sidebar-widget {
	.list-item {
		li {
			a {
				transition: all .3s ease-in-out;
				display: block;
				position: relative;
				line-height: 44px;
				font-size: 17px;
				color: var(--text-dark);
				.expander {
					position: absolute;
					background: 0 0;
					line-height: 50px;
					right: 0;
					top: 0;
					color: inherit;
					font-size: 12px;
				}
				span {
					position: absolute;
					right: 0;
					top: 0;
					line-height: 50px;
				}
			}
			.sub-menu {
				padding-left: 30px;
				a {
					font-size: 16px;
					line-height: 31px;
					color: #585858;
					&:hover {
						padding-left: 10px;
					}
				}
			}
			&:hover {
				>a {
					color: var(--p-rose);
				}
			}
		}
		margin-top: -17px;
	}
	.sidebar-title {
		font-size: 17px;
		text-transform: uppercase;
		letter-spacing: 1.5px;
		padding-bottom: 30px;
	}
	.price-ranger {
		.ui-widget-content {
			background: #eee;
			border: none;
			height: 5px;
			border-radius: 5px;
			margin-bottom: 22px;
			max-width: 350px;
		}
		.ui-slider-handle {
			width: 15px;
			height: 15px;
			background: #fff;
			border-radius: 50%;
			border: none;
			outline: 0;
			cursor: pointer;
			box-shadow: 0 0 10px 0 rgba(207,207,207,.61);
		}
		.ui-slider-horizontal {
			.ui-slider-handle {
				top: -5px;
			}
		}
		.ranger-min-max-block {
			li {
				display: inline-block;
				line-height: 30px;
				font-size: 18px;
				&:first-child {
					color: #989ca2;
				}
			}
			input {
				width: 50px;
				height: 30px;
				border: none;
				background: 0 0;
				text-align: center;
				color: #3e3e3e;
			}
		}
		.ui-widget-header {
			background: #212121;
		}
	}
	.size-filter {
		margin: 0 -8px;
		li {
			float: left;
			padding: 0 8px;
			a {
				display: block;
				text-align: center;
				width: 40px;
				height: 40px;
				border: 1px solid #dfdfdf;
				line-height: 38px;
				font-size: 15px;
				text-transform: uppercase;
				color: rgba(29,29,29,.4);
				&:hover {
					color: #fff;
					background: #212121;
					border-color: #212121;
				}
			}
		}
	}
	.color-filter {
		li {
			display: inline-block;
			a {
				display: block;
				margin-right: 10px;
				width: 35px;
				height: 35px;
				border-radius: 50%;
			}
			&:nth-child(1) {
				a {
					background: #9aa2ce;
				}
			}
			&:nth-child(2) {
				a {
					background: #00ead0;
				}
			}
			&:nth-child(3) {
				a {
					background: #ff4b68;
				}
			}
			&:nth-child(4) {
				a {
					background: #ffd657;
				}
			}
			&:nth-child(5) {
				a {
					background: #dc6cff;
				}
			}
			&:nth-child(6) {
				a {
					background: #57ff86;
				}
			}
		}
	}
	.sidebar-search {
		height: 60px;
		position: relative;
		input {
			width: 100%;
			height: 100%;
			border: 1px solid #e0e0e0;
			padding: 0 50px 0 25px;
			color: #a9a9a9;
		}
		&::placeholder {
			color: #a9a9a9;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			color: #a9a9a9;
		}
		&::-ms-input-placeholder {
			color: #a9a9a9;
		}
		button {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 50px;
			color: #b1b1b1;
			font-size: 20px;
			background: 0 0;
		}
	}
}
.theme-st-header {
	.top-header {
		.infoList {
			li {
				a {
					transition: all .3s ease-in-out;
				}
			}
		}
		.social-icon {
			a {
				transition: all .3s ease-in-out;
			}
		}
	}
}
.tran3s {
	transition: all .3s ease-in-out;
}
.op-partner-section-one {
	.img-box {
		transition: all .4s ease-in-out;
		background: #fff;
		border-radius: 50%;
		box-shadow: 15.436px 30.294px 50px 0 rgba(233,236,241,.5);
		width: 175px;
		height: 175px;
		margin: 0 auto 25px;
		a {
			display: block;
			height: 100%;
		}
		img {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			margin: 0 auto;
		}
		&:hover {
			transform: scale(1.1);
		}
	}
	.img-box.bx-b {
		width: 121px;
		height: 121px;
		margin-top: 45px;
	}
	.img-box.bx-d {
		width: 151px;
		height: 151px;
		margin-top: 25px;
	}
	.img-box.bx-f {
		width: 135px;
		height: 135px;
		margin-top: 20px;
	}
	.img-box.bx-g {
		width: 197px;
		height: 197px;
	}
	.img-box.bx-h {
		width: 138px;
		height: 138px;
		margin-top: 20px;
	}
}
.product-showcase {
	.single-product-case {
		.img-holder {
			.fvrt-icon {
				transition: all .4s ease-in-out;
				position: absolute;
				width: 45px;
				height: 45px;
				line-height: 45px;
				text-align: center;
				background: #fff;
				top: 40px;
				right: 30px;
				border-radius: 50%;
				font-size: 20px;
				color: var(--text-dark);
				transform: scale(0);
				opacity: 0;
				&:hover {
					background: var(--p-rose);
					color: #fff;
				}
			}
			.cart-button {
				transition: all .5s ease-in-out;
				position: absolute;
				left: 15px;
				right: 15px;
				bottom: 15px;
				line-height: 50px;
				background: #1f1f1f;
				color: #fff;
				font-size: 16px;
				text-align: center;
				text-transform: uppercase;
				opacity: 0;
				transform: translateY(70px);
			}
			position: relative;
			overflow: hidden;
			img {
				width: 100%;
				transition: all .6s ease-in-out;
			}
		}
		margin-bottom: 50px;
		&:hover {
			.img-holder {
				img {
					transform: scale3d(1.1,1.1,1);
				}
				.cart-button {
					opacity: 1;
					transform: translateY(0);
				}
				.fvrt-icon {
					transform: scale(1);
					opacity: 1;
				}
			}
		}
		.product-name {
			font-family: gilroy-semibold;
			font-size: 24px;
			color: var(--text-dark);
			margin: 35px 0 5px;
			display: block;
		}
		.price {
			display: inline-block;
			font-size: 20px;
			color: var(--text-dark);
		}
		.prev-price {
			display: inline-block;
			font-size: 20px;
			color: var(--text-dark);
			margin-right: 5px;
		}
		.tag {
			position: absolute;
			left: 0;
			top: 40px;
			width: 50px;
			line-height: 25px;
			text-align: center;
			text-transform: uppercase;
			font-size: 14px;
			background: #fff;
			color: var(--text-dark);
		}
	}
}
.service-modern-block {
	.more {
		transition: all .4s ease-in-out;
		font-size: 50px;
		color: var(--heading);
	}
	.wrapper {
		padding: 0 130px;
	}
	.row {
		margin: 0 -75px;
		[class*=col-] {
			padding: 0 75px;
		}
	}
	.num {
		font-weight: 100;
		color: rgba(0,0,0,.1);
		font-size: 80px;
	}
	.title {
		a {
			font-family: gilroy-bold !important;
			font-size: 48px;
			line-height: 50px;
			color: var(--heading);
			margin: 40px 0 25px;
		}
	}
	&:hover {
		.more {
			transform: translateX(20px);
		}
	}
}
.tran4s {
	transition: all .4s ease-in-out;
}
#mega-menu-holder {
	.img-box {
		.img {
			img {
				transition: all .5s ease-in-out;
				width: 100%;
			}
			display: block;
			overflow: hidden;
			background: #000;
			box-shadow: 0 30px 70px 0 rgba(223,227,234,.5);
		}
		display: block;
		text-align: center;
		&:hover {
			.img {
				img {
					opacity: .7;
					transform: scale3d(1.1,1.1,1);
				}
			}
		}
		.text {
			font-family: gilroy-semibold;
			text-transform: uppercase;
			font-size: 14px;
			letter-spacing: 1px;
			display: block;
			color: var(--text-dark);
			padding-top: 10px;
		}
	}
	padding: 0;
	position: static;
}
.tran5s {
	transition: all .5s ease-in-out;
}
.tran6s {
	transition: all .6s ease-in-out;
}
.theme-title-one {
	position: relative;
	.upper-title {
		font-size: 20px;
		text-transform: uppercase;
		color: var(--text-light);
		letter-spacing: 1.5px;
	}
	.upper-title.color-orange {
		color: var(--p-orange);
	}
	.main-title {
		span {
			position: relative;
		}
		i {
			font-style: normal;
			font-family: gilroy-ultralight;
		}
	}
	.underline {
		span {
			&:before {
				content: '';
				position: absolute;
				bottom: 7px;
				left: 3px;
				width: 99%;
				height: 11px;
				z-index: -1;
				background: #ecf4ff;
				border-radius: 5px;
			}
		}
	}
	.icon-box {
		display: inline-block;
		margin-bottom: 22px;
		position: relative;
		.bg-shape {
			transform: rotate(-90deg);
			transition: all 1s ease-out;
		}
		.icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50% ,-50%);
		}
	}
	.icon-box.show-pr {
		.bg-shape {
			transform: rotate(0);
		}
	}
}
.theme-main-menu {
	position: absolute;
	z-index: 99;
	top: 0;
	left: 0;
	right: 0;
	background: 0 0;
	padding: 70px 5% 10px;
	transition: all .4s ease-out;
	.logo {
		a {
			display: block;
		}
	}
	.right-content {
		.cart-action-wrapper {
			margin-right: 45px;
			.dropdown-toggle {
				height: 27px;
				position: relative;
				.item-count {
					position: absolute;
					width: 15px;
					height: 15px;
					border-radius: 50%;
					background: var(--text-dark);
					line-height: 15px;
					text-align: center;
					font-size: 9px;
					color: #fff;
					right: -12px;
					top: -10px;
				}
			}
			.dropdown-menu {
				padding: 40px 35px;
				min-width: 300px;
			}
			.cart-product-list {
				.item-img {
					width: 70px;
					height: 80px;
					display: block;
					float: left;
				}
				.item-info {
					width: calc(100% - 70px);
					float: left;
					padding-left: 25px;
					position: relative;
					.close {
						position: absolute;
						top: 13px;
						right: 0;
						font-size: 12px;
						color: rgba(31,31,31,.9);
						&:hover {
							color: #ff3612;
						}
					}
					.name {
						font-size: 18px;
						color: var(--text-dark);
						margin: 5px 0;
						padding-right: 12px;
					}
					.price {
						font-size: 20px;
						color: var(--text-dark);
						.quantity {
							font-size: 17px;
							color: rgba(31,31,31,.3);
							margin-left: 15px;
						}
					}
				}
				.selected-item {
					border-bottom: 1px solid #eee;
					padding-bottom: 30px;
					margin-bottom: 30px;
					&:last-child {
						margin-bottom: 14px;
					}
				}
			}
			.subtotal {
				.title {
					font-size: 18px;
					color: #1f1f1f;
				}
				.total-price {
					font-size: 20px;
					color: #1f1f1f;
				}
				padding-bottom: 7px;
			}
			.button-group {
				a {
					display: block;
					line-height: 45px;
					border: 1px solid #dbdbdb;
					text-align: center;
					font-size: 14px;
					text-transform: uppercase;
					color: var(--text-dark);
					margin-top: 15px;
					&:hover {
						color: #fff;
						background: var(--text-dark);
					}
				}
			}
		}
		.dropdown-toggle {
			&:after {
				display: none;
			}
			background: 0 0;
		}
		.dropdown-menu {
			background: #fff;
			box-shadow: 0 25px 50px 0 rgba(213,216,223,.5);
			border: 1px solid #f8f8f8;
			border-radius: 0;
			margin: 15px -35px 0 0;
		}
	}
	.contact-button {
		font-weight: 500;
		font-size: 15px;
		text-transform: uppercase;
	}
	.contact-button.button-white-bg {
		background: #fff;
		border: none;
		color: var(--text-dark);
		box-shadow: 0 5px 20px 0 rgba(0,0,0,.1);
		&:hover {
			color: #fff;
		}
	}
}
.theme-menu-one {
	.right-content {
		margin-left: 15px;
	}
}
.theme-main-menu.menu-text-white {
	.right-content {
		.cart-action-wrapper {
			.dropdown-toggle {
				.item-count {
					background: var(--p-rose);
				}
			}
		}
	}
}
.theme-menu-two {
	#mega-menu-holder {
		margin-left: 11%;
	}
}
.sticky-menu.fixed {
	position: fixed !important;
	background: #fff;
	margin: 0 !important;
	padding-top: 15px;
	padding-bottom: 15px;
	box-shadow: 0 13px 35px -12px rgba(35,35,35,.1);
}
.sticky-menu.fixed.menu-text-white {
	background: #110a29;
}
.rogan-hero-section {
	z-index: 9;
	.main-wrapper {
		z-index: 5;
	}
	.banner-upper-heading {
		color: var(--text-dark);
		font-size: 20px;
		text-transform: uppercase;
		span {
			color: var(--p-green);
		}
	}
	.banner-main-title.underline {
		span {
			position: relative;
			&:before {
				content: '';
				position: absolute;
				bottom: 7px;
				left: 0;
				width: 99%;
				height: 11px;
				z-index: -1;
				background: #ecf4ff;
			}
		}
	}
	.banner-sub-title {
		font-size: 23px;
		color: #929292;
	}
	.theme-btn {
		margin-top: 15px;
	}
	.video-button-one {
		margin-top: 15px;
	}
}
.rogan-hero-one {
	.solid-button-one {
		margin-right: 20px;
	}
	.shape-one {
		position: absolute;
		right: 0;
		top: 30px;
	}
	.main-illustration {
		position: absolute;
		height: 100%;
		width: 1100px;
		right: -170px;
		top: 0;
		iframe {
			width: 100%;
			height: 100%;
		}
	}
	.line-shape-one {
		top: 0;
		right: 0;
		z-index: -1;
	}
	.line-shape-two {
		top: 0;
		left: 0;
		z-index: -1;
	}
	.light-lamp {
		top: 113px;
		right: 30px;
	}
	.shape-two {
		left: 24%;
		top: 9%;
		animation: rotated 20s infinite linear;
	}
	.shape-three {
		left: 40%;
		top: 13%;
		animation: animationFramesOne 25s infinite linear;
	}
	.shape-four {
		right: 20%;
		top: 18%;
		animation: animationFramesOne 30s alternate infinite linear;
	}
	.shape-five {
		right: 11%;
		top: 23%;
		animation: animationFramesFour 25s alternate infinite linear;
	}
	.shape-six {
		right: 40%;
		top: 36%;
		animation: rotated 15s infinite linear;
	}
	.shape-seven {
		right: 3%;
		bottom: 11%;
		animation: animationFramesOne 25s infinite linear;
	}
	.shape-eight {
		right: 19%;
		bottom: 14%;
		animation: animationFramesOne 30s alternate infinite linear;
	}
	.shape-nine {
		right: 55%;
		bottom: 0;
		animation: rotated 12s infinite linear;
	}
	.shape-ten {
		left: 27%;
		bottom: 16%;
		animation: rotated 14s infinite linear;
	}
	.shape-eleven {
		left: 15%;
		bottom: 26%;
		animation: animationFramesOne 25s infinite linear;
	}
	.shape-twelve {
		left: 4%;
		top: 41%;
		animation: rotated 14s infinite linear;
	}
}
.rogan-hero-section.text-center {
	.theme-btn {
		margin-left: 10px;
		margin-right: 10px;
	}
}
.rogan-hero-two {
	.main-illustration {
		margin: 80px auto 0;
	}
	.line-shape-one {
		top: 0;
		right: 0;
		z-index: -1;
	}
	.line-shape-two {
		top: 0;
		left: 0;
		z-index: -1;
	}
	.shape-one {
		left: 12%;
		top: 17%;
		animation: rotated 20s infinite linear;
	}
	.shape-two {
		left: 36%;
		top: 12%;
		animation: animationFramesOne 25s infinite linear;
	}
	.shape-three {
		left: 73%;
		top: 13%;
		animation: rotated 20s infinite linear;
	}
	.shape-four {
		right: 8%;
		top: 17%;
		animation: animationFramesFour 25s alternate infinite linear;
	}
	.shape-five {
		left: 17%;
		top: 29%;
	}
	.shape-six {
		right: 17%;
		top: 27%;
	}
	.shape-seven {
		left: 8%;
		top: 42%;
		animation: rotated 20s infinite linear;
	}
	.shape-eight {
		right: 5%;
		top: 45%;
		animation: rotated 20s infinite linear;
	}
	.shape-nine {
		left: 11%;
		top: 57%;
		animation: rotated 20s infinite linear;
	}
}
.rogan-hero-section.rogan-hero-three {
	overflow: hidden;
	.banner-upper-heading {
		font-family: gilroy-light;
		font-size: 22px;
		color: var(--text-color);
		letter-spacing: 1.4px;
	}
	.banner-main-title {
		font-family: gilroy-black;
		font-size: 80px;
		line-height: 92px;
		span {
			font-family: gilroy-ultralight;
		}
	}
	.solid-button-one {
		margin-right: 45px;
	}
	.main-image-shape {
		width: 990px;
		height: 990px;
		border-radius: 50%;
		top: -205px;
		right: -185px;
	}
	.shape-one {
		width: 990px;
		height: 990px;
		top: -108px;
		right: -184px;
		z-index: -1;
		border-radius: 50%;
		background: linear-gradient(134deg,#7be7ff,#5bffd2);
	}
	.shape-two {
		width: 70px;
		height: 70px;
		top: 16%;
		right: 770px;
		z-index: 1;
		background: linear-gradient(321deg,rgba(255,56,153,.36),rgba(255,139,64,.36));
		border-radius: 50%;
	}
	.shape-three {
		width: 378px;
		height: 378px;
		bottom: 9%;
		right: 287px;
		z-index: 1;
		background: linear-gradient(321deg,rgba(250,217,97,.1),rgba(247,107,28,.1));
		border-radius: 50%;
	}
	.shape-four {
		width: 12px;
		height: 12px;
		top: 20%;
		left: 28%;
		animation: animationFramesOne 25s infinite linear;
	}
	.shape-five {
		width: 25px;
		height: 25px;
		top: 44%;
		left: 4%;
		animation: animationFramesOne 30s alternate infinite linear;
	}
	.shape-six {
		width: 15px;
		height: 15px;
		top: 49%;
		left: 51%;
		animation: animationFramesTwo 35s infinite linear;
	}
	.shape-seven {
		width: 12px;
		height: 12px;
		bottom: 20%;
		left: 33%;
		animation: animationFramesOne 30s infinite linear;
	}
}
.rogan-hero-section.rogan-hero-four {
	overflow: hidden;
	.banner-main-title {
		font-size: 80px;
		line-height: 87px;
	}
	.theme-btn {
		margin-right: 45px;
	}
	.bg-shape {
		bottom: 0;
		right: 0;
	}
	.shape-two {
		width: 12px;
		height: 12px;
		top: 20%;
		left: 28%;
		animation: animationFramesOne 25s infinite linear;
	}
	.shape-three {
		width: 25px;
		height: 25px;
		top: 20%;
		right: 11%;
		animation: animationFramesTwo 25s infinite linear;
	}
	.shape-four {
		width: 20px;
		height: 20px;
		top: 50%;
		left: 47%;
		animation: scale-up-two 5s infinite linear;
	}
	.shape-five {
		width: 12px;
		height: 12px;
		bottom: 11%;
		left: 33%;
		animation: animationFramesOne 30s infinite linear;
	}
	.shape-six {
		width: 25px;
		height: 25px;
		top: 44%;
		left: 4%;
		animation: animationFramesOne 30s alternate infinite linear;
	}
	.screen-wrapper {
		z-index: 0;
	}
	.screen-one {
		right: 180px;
		top: 200px;
		overflow: hidden;
		.screen {
			left: 97px;
			top: 58px;
		}
	}
	.screen-two {
		right: 365px;
		top: 315px;
		overflow: hidden;
		.screen {
			left: 39px;
			top: 28px;
		}
	}
	.screen-three {
		right: 295px;
		bottom: -180px;
		overflow: hidden;
	}
	.screen-four {
		right: 37%;
		top: 44%;
		overflow: hidden;
		.screen {
			left: 51px;
			top: 14px;
		}
	}
}
.rogan-hero-section.rogan-hero-five {
	.banner-main-title {
		font-family: gilroy-black;
		font-size: 80px;
		line-height: 92px;
		span {
			font-family: gilroy-ultralight;
		}
	}
	.shape-wrapper {
		width: 53%;
		left: auto;
		right: 0;
		overflow: hidden;
		background: url('../images/home/banner2.jpg') no-repeat center;
		background-size: cover;
		.d-text {
			font-family: gilroy-black;
			font-size: 600px;
			position: absolute;
			top: 40px;
			left: -172px;
			color: #fff;
			line-height: initial;
		}
	}
	.video-button {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		text-align: center;
		line-height: 60px;
		position: absolute;
		right: 52%;
		bottom: 0;
		color: #fff;
		font-size: 32px;
		background: linear-gradient(-90deg,#a3f95d 0,#0de49d 100%);
	}
}
.our-core-feature.style-one {
	.shape-one {
		top: 17%;
		left: 25%;
		animation: animationFramesOne 30s infinite linear;
	}
	.shape-two {
		top: 45%;
		left: 4%;
		animation: animationFramesOne 35s alternate infinite linear;
	}
	.shape-three {
		bottom: 13%;
		left: 24%;
		animation: animationFramesTwo 35s infinite linear;
	}
	.shape-four {
		bottom: -8%;
		left: 41%;
		animation: animationFramesOne 25s infinite linear;
	}
	.shape-five {
		right: 0;
		top: 21%;
	}
	.shape-six {
		right: 14%;
		bottom: 33%;
	}
}
.our-core-feature {
	.feature-block-wrapper {
		padding-right: 60px;
		@media(max-width: 767px){
		
			padding-right: 0px;
		}
	}
	
}
.feature-block-one {
	background: #fff;
	border-radius: 5px;
	overflow: hidden;
	padding: 60px 30px 55px 40px;
	position: relative;
	z-index: 5;
	box-shadow: 0 30px 70px 0 rgba(223,227,234,.5);
	.icon {
		font-size: 60px;
	}
	img{
		width: 75px;
	}
	.read-more {
		font-size: 30px;
		color: var(--text-light);
		margin-top: 15px;
	}
	&:hover {
		.read-more {
			color: var(--p-green);
		}
	}
	.icon-one {
		color: var(--cyan);
	}
	.icon-two {
		color: var(--p-green);
	}
	.icon-three {
		color: var(--redOne);
	}
}
.feature-block-one.hover-state.main-p-color {
	&:before {
		background: var(--rose-gr-bg);
	}
}
.feature-block-one.main-p-color {
	&:hover {
		.read-more {
			color: #fff;
		}
	}
}
.our-core-feature.style-two {
	.shape-one {
		width: 1067px;
		height: 1067px;
		top: 75px;
		right: -355px;
		z-index: -1;
	}
	.shape-two {
		width: 1067px;
		height: 1067px;
		top: 0;
		right: -377px;
	}
	.shape-three {
		width: 20px;
		height: 20px;
		top: 60px;
		left: 32%;
		animation: animationFramesFive 25s infinite linear;
	}
	.shape-four {
		width: 10px;
		height: 10px;
		left: 6%;
		top: 42%;
		animation: animationFramesOne 30s infinite linear;
	}
	.shape-five {
		width: 10px;
		height: 10px;
		left: 6%;
		bottom: 100px;
		animation: animationFramesTwo 30s infinite linear;
	}
	.shape-six {
		width: 20px;
		height: 20px;
		left: 35%;
		bottom: 29%;
		animation: animationFramesOne 25s infinite linear;
	}
	.shape-seven {
		width: 10px;
		height: 10px;
		right: 40%;
		bottom: 13%;
		animation: animationFramesTwo 25s infinite linear;
	}
}
.our-core-feature.style-three {
	.shape-one {
		top: 46%;
		left: 12%;
	}
	.shape-two {
		width: 20px;
		height: 20px;
		top: 50px;
		right: 12%;
		animation: animationFramesOne 25s infinite linear;
	}
	.shape-three {
		width: 8px;
		height: 8px;
		top: 50%;
		right: 5%;
	}
	.shape-four {
		width: 12px;
		height: 12px;
		bottom: 13%;
		left: 30%;
		animation: animationFramesOne 25s infinite linear;
	}
	.shape-five {
		width: 20px;
		height: 20px;
		top: 45%;
		left: 11%;
		animation: animationFramesOne 25s infinite linear;
	}
}
.about-us-block-one {
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 635px;
		top: -105px;
		left: 0;
		background: url('../images/shape/20.svg') no-repeat center top;
		background-size: cover;
		z-index: -1;
	}
	.bold-text {
		font-size: 20px;
		line-height: 34px;
		color: var(--text-dark);
	}
	.inner-wrapper {
		@media(max-width:767px){
			padding-top: 700px;
		}
		.path-shape-holder {
			position: absolute;
			height: 100%;
			z-index: -1;
			right: -20px;
			top: -10px;
			max-width: 50%;
			.image-box {
				position: absolute;
				right: 25px;
				top: -30px;
			}
		}
	}
	.shape-wrapper {
		.shape-one {
			top: 22%;
			left: -70px;
		}
		.shape-two {
			right: 0;
			top: 35%;
			animation: animationFramesOne 30s infinite linear;
		}
		.shape-three {
			right: 20%;
			top: 25%;
			animation: animationFramesTwo 25s infinite linear;
		}
		.shape-four {
			right: 13%;
			bottom: -8%;
			animation: animationFramesOne 25s infinite linear;
		}
		.shape-five {
			right: 34%;
			bottom: -6%;
			animation: rotated 20s infinite linear;
		}
		.shape-six {
			right: 39%;
			top: 48%;
			animation: rotated 20s infinite linear;
		}
	}
}
.about-us-block-two {
	.mark-text {
		color: var(--text-dark);
		font-size: 27px;
		font-style: italic;
	}
	.main-img-box {
		display: inline-block;
		.img-one {
			border-radius: 15px;
			overflow: hidden;
			box-shadow: 0 71px 100px 0 rgba(140,140,140,.33);
			&:before {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				border: 70px solid #fff;
				border-radius: 50%;
				bottom: -150px;
				left: -150px;
				transition: all 1.9s ease-in-out;
			}
		}
		.img-two {
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 25px 50px 0 rgba(140,140,140,.33);
			position: absolute;
			z-index: -1;
			left: -165px;
			top: 50%;
			margin-top: -50%;
		}
	}
	.main-img-box.show-pr {
		.img-one {
			&:before {
				width: 300px;
				height: 300px;
			}
		}
	}
	.shape-one {
		width: 960px;
		height: 960px;
		left: -290px;
		top: 70px;
		z-index: -1;
	}
	.shape-two {
		width: 960px;
		height: 960px;
		left: -330px;
		top: 0;
		z-index: 1;
	}
	.shape-three {
		width: 20px;
		height: 20px;
		top: 50px;
		right: 40%;
		animation: animationFramesOne 25s infinite linear;
	}
	.shape-four {
		width: 20px;
		height: 20px;
		top: 41%;
		right: 2%;
		animation: scale-up-two 5s infinite linear;
	}
	.shape-five {
		width: 10px;
		height: 10px;
		bottom: 20%;
		right: 30%;
		animation: animationFramesOne 35s infinite linear;
	}
	.box-shape {
		width: 209px;
		height: 209px;
		border-radius: 5px;
		background: var(--p-green);
		position: absolute;
		top: -105px;
		left: -105px;
		z-index: 1;
	}
	.dot-shape {
		position: absolute;
		left: -145px;
		bottom: -150px;
		z-index: -1;
	}
}
.our-service-one {
	.inner-wrapper {
		.theme-title-one {
			position: absolute;
			top: 80px;
			left: 0;
			z-index: 1;
		}
	}
	.shape-wrapper {
		.shape-one {
			left: 0;
			top: 65%;
		}
		.shape-two {
			left: 29%;
			top: 20%;
			animation: animationFramesOne 30s infinite linear;
		}
		.shape-three {
			left: 11%;
			top: 40%;
			animation: animationFramesTwo 25s infinite linear;
		}
		.shape-four {
			left: 10%;
			top: 66%;
			animation: animationFramesOne 35s infinite linear;
		}
		.shape-five {
			left: 10%;
			top: 91%;
			animation: rotated 20s infinite linear;
		}
		.shape-six {
			left: 26%;
			top: 96%;
			animation: animationFramesOne 35s infinite linear;
		}
		.shape-seven {
			left: 9%;
			top: 94%;
		}
		.shape-eight {
			right: 0;
			top: 9%;
		}
		.shape-nine {
			right: 6%;
			top: 11%;
		}
	}
}
.testimonial-section-classic {
	padding: 174px 0 400px;
	overflow: hidden;
	.main-bg {
		position: absolute;
		width: 100%;
		top: 0;
		right: 0;
		z-index: -1;
	}
	.container{
		.theme-title-one{
			.main-title {
				@media(max-width: 767px){
					padding-top:150px;	
				}
			}
		}
	}
	.main-content {
		position: relative;
		background: #fff;
		border-radius: 20px;
		box-shadow: 0 16px 25px 0 rgba(0,0,0,.03);
		padding: 70px 100px 50px;
		z-index: 5;
		&:before {
			content: url('../images/icon/icon8.svg');
			position: absolute;
			top: 50px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.inner-container {
		@media(max-width: 767px){
			width: 90%;
		}
		.main-content{
			@media(max-width: 767px){
				padding:20px;
			}	
		}
		width: 69%;
		margin: 60px auto 0;
		position: relative;
		z-index: 1;
		text-align: center;
		&:before {
			content: '';
			position: absolute;
			bottom: -30px;
			left: 35px;
			right: 35px;
			height: 200px;
			background: #fff;
			border-radius: 20px;
			box-shadow: 0 15px 23px 0 rgba(35,49,64,.09);
			z-index: -1;
		}
		&:after {
			content: '';
			position: absolute;
			bottom: -55px;
			left: 80px;
			right: 80px;
			height: 200px;
			background: #fff;
			border-radius: 20px;
			box-shadow: 0 14px 21px 0 rgba(0,0,0,.03);
			z-index: -3;
		}
		p {
			font-size: 22px;
			line-height: 45px;
		}
		.name {
			// font-family: Roboto,sans-serif;
			font-weight: 700;
			font-size: 18px;
			text-transform: uppercase;
			padding: 15px 0 4px;
		}
		.designation {
			color: var(--text-light);
		}
	}
	.owl-theme {
		.owl-nav {
			position: absolute;
			width: 100%;
			left: 0;
			bottom: -228px;
			[class*=owl-] {
				background: 0 0;
				margin: 0 10px;
				padding: 0;
				font-size: 35px;
				color: #5fa775;
				opacity: .5;
				&:hover {
					opacity: 1;
				}
			}
		}
	}
	.shape-wrapper {
		.people {
			position: absolute;
			border-radius: 50%;
			width: 110px;
			height: 110px;
			box-shadow: 0 10px 20px 0 rgba(20,44,56,.25);
			&:nth-child(1) {
				top: 14%;
				left: 13%;
				animation: scale-up-one 14s infinite linear;
			}
			&:nth-child(2) {
				top: 46%;
				left: 4%;
				animation: scale-up-two 9s infinite linear;
			}
			&:nth-child(3) {
				bottom: 9%;
				left: 10%;
				animation: scale-up-three 8s infinite linear;
			}
			&:nth-child(4) {
				top: 14%;
				right: 19%;
				animation: scale-up-two 10s infinite linear;
			}
			&:nth-child(5) {
				top: 44%;
				right: 2%;
				animation: scale-up-three 11s infinite linear;
			}
			&:nth-child(6) {
				bottom: 9%;
				right: 10%;
				animation: scale-up-one 12s infinite linear;
			}
		}
	}
}
.testimonial-section-classic.style-two {
	padding: 250px 0 310px;
	.inner-container {
		&:after {
			box-shadow: none;
		}
		&:before {
			box-shadow: none;
		}
	}
	.main-content {
		box-shadow: none;
	}
	.shape-one {
		left: 30%;
		top: 13%;
	}
	.shape-two {
		right: 30%;
		top: 16%;
	}
	.shape-three {
		left: 14%;
		top: 39%;
	}
	.shape-four {
		right: 15%;
		top: 41%;
	}
	.shape-five {
		left: 17%;
		top: 69%;
	}
	.shape-six {
		right: 14%;
		top: 69%;
	}
	.shape-seven {
		left: 35%;
		top: 89%;
	}
	.shape-eight {
		right: 34%;
		top: 87%;
	}
	.shape-wrapper {
		.people {
			&:nth-child(1) {
				top: 24%;
				left: 18%;
			}
			&:nth-child(2) {
				top: 53%;
				left: 6%;
			}
			&:nth-child(3) {
				bottom: 14%;
				left: 20%;
			}
			&:nth-child(4) {
				top: 25%;
				right: 22%;
			}
			&:nth-child(5) {
				top: 53%;
				right: 10%;
			}
			&:nth-child(6) {
				bottom: 12%;
				right: 22%;
			}
		}
	}
}
.testimonial-section-classic.style-one {
	.shape-one {
		left: 10%;
		top: 2%;
	}
}
.testimonial-classic-v2 {
	.main-bg-wrapper {
		margin: 0 0 0 31.5%;
		background: url('../images/home/13.jpg') no-repeat center;
		background-size: cover;
		height: 670px;
		.quote {
			position: absolute;
			width: 100%;
			height: 100%;
			overflow: hidden;
			top: 0;
			bottom: 0;
			span {
				position: absolute;
				font-family: gilroy-black;
				font-size: 400px;
				color: #fff;
				left: 0;
				bottom: 0;
			}
		}
		.main-slider-wrapper {
			position: absolute;
			max-width: 500px;
			z-index: 9;
			top: 0;
			left: 0;
			background: #765bff;
			padding: 85px 100px 80px 75px;
			transform: translate(-50%,-34%);
			z-index: 1;
			.icon {
				width: 40px;
				height: 31px;
			}
			.item {
				p {
					font-size: 28px;
					line-height: 52px;
					color: #fff;
					font-style: italic;
					margin: 15px 0 80px 0;
				}
				.name {
					padding-left: 30px;
					h6 {
						font-family: gilroy-semibold;
						font-size: 20px;
						color: #fff;
						margin-bottom: 2px;
					}
					span {
						text-transform: uppercase;
						font-size: 15px;
						text-transform: uppercase;
						color: #fff;
						letter-spacing: 1.2px;
					}
				}
				img {
					width: 50px;
					height: 50px;
					border-radius: 50%;
				}
			}
		}
	}
	.owl-theme {
		.owl-nav {
			margin: 0;
		}
		.owl-dots {
			.owl-dot {
				span {
					width: 10px;
					height: 10px;
					background: 0 0;
					border: 2px solid rgba(255,255,255,.5);
					border-radius: 50%;
					padding: 0;
					margin: 0 3px;
				}
				&:hover {
					span {
						background: #fff;
						border-color: #fff;
					}
				}
			}
			.owl-dot.active {
				span {
					background: #fff;
					border-color: #fff;
				}
			}
			position: absolute;
			bottom: 0;
			right: -35px;
			z-index: 1;
		}
	}
}
.testimonial-section-standard {
	&:before {
		content: url('../images/icon/icon10.svg');
		position: absolute;
		right: 0;
		top: 40%;
		z-index: -1;
	}
	.slider-wrapper {
		max-width: 1200px;
		margin-right: 85px;
		margin-left: -225px;
	}
	.customer-content {
		border-radius: 4px;
		background-color: #fff;
		box-shadow: 0 20px 40px 0 rgba(60,60,60,.05);
		padding: 45px 40px 55px;
		margin: 30px 24px 60px;
		position: relative;
		&:before {
			content: url('../images/icon/icon9.svg');
			position: absolute;
			right: 40px;
			bottom: 55px;
		}
		.c-img {
			width: 42px;
			height: 42px;
			border-radius: 50%;
			margin-right: 20px;
		}
		h6 {
			font-family: gilroy-semibold;
			font-size: 18px;
		}
		span {
			font-size: 16px;
			color: var(--text-light);
		}
	}
	.shape-one {
		width: 850px;
		height: 850px;
		top: 75px;
		left: -320px;
		z-index: -1;
	}
	.shape-two {
		width: 850px;
		height: 850px;
		top: 0;
		left: -330px;
	}
	.shape-three {
		width: 10px;
		height: 10px;
		top: 110px;
		right: 30%;
	}
	.shape-four {
		width: 20px;
		height: 20px;
		bottom: 110px;
		right: 40%;
	}
	.shape-five {
		left: 0;
		top: 0;
	}
}
.testimonial-section-standard.bg-color {
	background: #f4f8ff;
	padding: 140px 0 110px;
}
.consultation-section {
	&:before {
		@media(max-width: 767px){
			display: none;
		}
		content: url(../images/shape/35.svg);
		position: absolute;
		left: 0;
		top: -145px;
		z-index: -1;
	}
	iframe.animate-svg {
		@media(max-width: 767px){
			display: none;
		}
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 1050px;
		height: 1050px;
	}
}
.theme-form-style-one {
	input {
		width: 100%;
		max-width: 100%;
		border: 1px solid #e6e6e6;
		border-radius: 3px;
		color: var(--text-light);
		margin-bottom: 25px;
		height: 50px;
		padding: 0 20px;
		&:focus {
			border-color: #6a6a6a;
		}
	}
	textarea {
		width: 100%;
		max-width: 100%;
		border: 1px solid #e6e6e6;
		border-radius: 3px;
		color: var(--text-light);
		margin-bottom: 25px;
		height: 170px;
		max-height: 170px;
		padding: 20px;
		resize: none;
		&:focus {
			border-color: #6a6a6a;
		}
	}
}
.home-blog-one {
	&:before {
		content: url(../images/shape/36.svg);
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
	}
	&:after {
		content: url(../images/shape/37.svg);
		position: absolute;
		left: 0;
		top: 85px;
		z-index: -1;
	}
}
.blog-post-block-one {
	position: relative;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	.flip-box-front {
		background: #fff;
		padding: 50px 15px 50px 35px;
		border-radius: 3px;
		box-shadow: 0 30px 70px 0 rgba(226,232,236,.5);
		.author-img {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			float: left;
		}
		.author-info {
			float: left;
			padding-left: 22px;
		}
	}
	.author-info {
		.name {
			font-size: 20px;
			padding-top: 7px;
		}
		.date {
			font-size: 16px;
			color: #838b98;
		}
	}
	.title {
		font-size: 24px;
		line-height: 32px;
		color: var(--heading);
		margin: 28px 0 20px;
	}
	.flip-box-back {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		border-radius: 3px;
		padding: 50px 15px 50px 35px;
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
		-webkit-transition-property: transform;
		transition-property: transform;
		-webkit-transition-duration: .4s;
		transition-duration: .4s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
		background: linear-gradient(-60deg,#82ef92 0,#0ad58b 100%);
		.author-info {
			.date {
				color: #fff;
			}
			.name {
				color: #fff;
				padding: 0 0 5px;
			}
		}
		.title {
			color: #fff;
		}
		.more {
			color: #fff;
			font-size: 40px;
			position: absolute;
			left: 35px;
			bottom: 30px;
		}
	}
	&:hover {
		.flip-box-back {
			transform-origin: center top;
			-webkit-transform: scaleY(1);
			transform: scaleY(1);
		}
	}
}
.newsletter-one {
	padding-bottom: 25px;
	.shape-one {
		left: 50%;
		top: -20%;
	}
	.shape-two {
		left: 8%;
		top: 53%;
	}
	.shape-three {
		left: 15%;
		bottom: 0;
	}
	.shape-four {
		right: 3%;
		top: 51%;
	}
	.shape-five {
		right: 20%;
		top: 70%;
	}
	.shape-six {
		top: -20%;
		left: 0;
	}
	.shape-seven {
		right: 0;
		top: -24%;
	}
}
.newsletter-two {
	background: url('../images/shape/dot-bg.svg') repeat;
	padding: 70px 0 90px;
	.shape-one {
		width: 16px;
		height: 16px;
		top: 35%;
		left: 18%;
	}
	.shape-two {
		width: 11px;
		height: 11px;
		bottom: 0;
		right: 10%;
	}
}
.newsletter-section.newsletter-two {
	.main-wrapper {
		form {
			input {
				box-shadow: 0 20px 30px 0 rgba(236,240,242,.5);
			}
			.button-rose {
				background: var(--rose-gr-bg);
				&:hover {
					background: var(--rose-gr-reverse);
				}
			}
			.button-orange {
				background: var(--p-orange);
			}
		}
	}
}
footer.top-border {
	border-top: 1px solid rgba(0,0,0,.06);
}
.top-footer {
	[class*=col-] {
		margin-bottom: 40px;
	}
}
.footer-title {
	font-family: Roboto,sans-serif;
	font-size: 22px;
	padding: 0 0 25px;
}
.bottom-footer-content {
	padding: 42px 0;
	p {
		text-align: center;
	}
}
.main-p-color {
	.footer-about-widget {
		.email {
			&:hover {
				color: var(--p-rose);
			}
		}
		.phone {
			&:hover {
				color: var(--p-rose);
			}
		}
	}
	.footer-list {
		ul {
			li {
				a {
					&:hover {
						color: var(--p-rose);
					}
				}
			}
		}
	}
	.footer-information {
		ul {
			li {
				a {
					&:hover {
						background: var(--p-rose);
						border-color: var(--p-rose);
					}
				}
			}
		}
	}
}
.color-orange {
	.footer-about-widget {
		.email {
			&:hover {
				color: var(--p-orange);
			}
		}
		.phone {
			&:hover {
				color: var(--p-orange);
			}
		}
	}
	.footer-list {
		ul {
			li {
				a {
					&:hover {
						color: var(--p-orange);
					}
				}
			}
		}
	}
	.footer-information {
		ul {
			li {
				a {
					&:hover {
						background: var(--p-orange);
						border-color: var(--p-orange);
					}
				}
			}
		}
	}
}
.achivement-section {
	.shape-bg {
		top: -11%;
		right: 0;
		z-index: -1;
	}
}
.theme-counter-one {
	display: inline-block;
	.inner-round-shape {
		width: 440px;
		height: 440px;
		border-radius: 50%;
		border: 1px solid #efefef;
	}
	.center-shape {
		width: 120px;
		height: 120px;
		background: #fff;
		top: 50%;
		left: 50%;
		border-radius: 50%;
		transform: translate(-50% ,-50%);
		box-shadow: 0 25px 70px 0 rgba(240,234,234,.5);
		img {
			margin: 0 auto;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.shape-one {
		top: -5%;
		left: -10%;
		animation: rotated 20s infinite linear;
	}
	.shape-two {
		top: -34%;
		left: 50%;
		animation: rotated 20s infinite linear;
	}
	.shape-three {
		top: 0;
		right: -23%;
		animation: rotated 20s infinite linear;
	}
	.shape-four {
		top: 48%;
		left: -48%;
		animation: rotated 20s infinite linear;
	}
	.shape-five {
		bottom: -5%;
		left: -10%;
		animation: rotated 20s infinite linear;
	}
	.shape-six {
		bottom: -34%;
		left: 50%;
		animation: rotated 20s infinite linear;
	}
	.shape-seven {
		bottom: 0;
		right: -23%;
		animation: rotated 20s infinite linear;
	}
}
.counter-box-one {
	width: 175px;
	height: 175px;
	border-radius: 50%;
	background: #fff;
	box-shadow: 0 30px 70px 0 rgba(233,237,244,.5);
	z-index: 1;
	text-align: center;
	padding-top: 46px;
	.number {
		font-family: Roboto,sans-serif;
		font-size: 42px;
		font-weight: 500;
	}
	.number.color-one {
		color: #1dcdfb;
	}
	.number.color-two {
		color: #ffcc42;
	}
	.number.color-three {
		color: #0de49d;
	}
	.number.color-four {
		color: #ff539e;
	}
	p {
		font-size: 18px;
		margin-top: -10px;
		color: var(--text-light);
	}
}
.inner-round-shape {
	.counter-box-one {
		&:nth-child(1) {
			top: 0;
			left: 50%;
			transform: translate(-50% ,-50%);
		}
		&:nth-child(2) {
			top: 50%;
			left: 0;
			transform: translate(-50% ,-50%);
		}
		&:nth-child(3) {
			top: 50%;
			right: 0;
			transform: translate(50% ,-50%);
		}
		&:nth-child(4) {
			bottom: 0;
			left: 50%;
			transform: translate(-50% ,50%);
			box-shadow: 0 25px 70px 0 rgba(240,234,234,.5);
		}
	}
}
.why-choose-us {
	background: linear-gradient(140deg,#68e57b 0,#05b588 100%);
	overflow: hidden;
	z-index: 5;
	.shape-wrapper {
		overflow: hidden;
		.big-round-one {
			position: absolute;
			width: 662px;
			height: 662px;
			background: rgba(255,255,255,.03);
			top: -346px;
			left: -75px;
			border-radius: 50%;
		}
		.big-round-two {
			position: absolute;
			width: 1372px;
			height: 1372px;
			background: rgba(255,255,255,.03);
			top: -689px;
			left: -368px;
			border-radius: 50%;
			z-index: -1;
		}
		.big-round-three {
			position: absolute;
			width: 2242px;
			height: 2242px;
			background: rgba(255,255,255,.03);
			top: -1161px;
			left: -779px;
			border-radius: 50%;
			z-index: -3;
		}
	}
	.text-wrapper {
		max-width: 500px;
		float: right;
		.title-box {
			display: inline-block;
			line-height: 35px;
			font-size: 13px;
			text-transform: uppercase;
			color: #fff;
			padding: 0 30px;
			border: 1px solid rgba(255,255,255,.2);
			border-radius: 3px;
		}
		.bottom-title {
			font-size: 24px;
			line-height: 34px;
			color: #fff;
			padding: 45px 0 35px;
		}
		ul {
			li {
				color: #fff;
				font-size: 18px;
				line-height: 30px;
				position: relative;
				padding: 0 0 15px 40px;
				&:before {
					content: "\f103";
					font-family: Flaticon;
					position: absolute;
					top: 0;
					left: 0;
					line-height: 32px;
					font-size: 24px;
				}
			}
			border-bottom: 1px solid rgba(222,243,222,.3);
			padding-bottom: 48px;
			margin-bottom: 55px;
		}
		.director-speech {
			position: relative;
			.d-img {
				width: 55px;
				height: 55px;
				border-radius: 50%;
				float: left;
			}
			.bio-block {
				float: left;
				padding-left: 25px;
				.name {
					font-size: 20px;
					color: #fff;
					padding: 2px 0;
				}
				span {
					font-size: 18px;
					color: #fff;
					font-style: italic;
				}
			}
			.sign {
				position: absolute;
				right: 0;
				top: -32px;
			}
		}
	}
	.screen-preview {
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 50%;
	}
}
.project-gallery-home-one {
	.slider-item-wrapper {
		padding: 0 35px;
	}
	.gallery-polar-state {
		border-radius: 5px;
		overflow: hidden;
		box-shadow: 0 40px 70px 0 rgba(73,79,89,.14);
		margin: 100px 0;
		.img-holder {
			img {
				border-radius: 3px;
			}
		}
	}
	.owl-controls {
		position: absolute;
		right: 35%;
		z-index: 1;
		top: -60px;
		text-align: right;
	}
	.owl-theme {
		.owl-nav {
			[class*=owl-] {
				background: 0 0;
				margin: 0 10px;
				padding: 0;
				font-size: 30px;
				color: rgba(0,0,0,.4);
				&:hover {
					color: var(--p-rose);
				}
			}
		}
	}
}
.gallery-polar-state.p-green {
	.img-holder {
		background: var(--p-green);
	}
}
.contact-home-one.color-orange {
	.contact-text {
		p {
			&:before {
				background: var(--p-orange);
			}
		}
		.call-to-dial {
			&:hover {
				color: var(--p-orange);
			}
		}
	}
}
.theme-form-style-two {
	background: #fff;
	padding: 65px 60px;
	box-shadow: 19.799px 19.799px 100px 0 rgba(96,96,96,.08);
	input {
		width: 100%;
		border: none;
		border-bottom: 2px solid #eaeaea;
		height: 50px;
		margin-bottom: 55px;
		&:focus {
			border-bottom-color: #6a6a6a;
		}
	}
	textarea {
		width: 100%;
		border: none;
		border-bottom: 2px solid #eaeaea;
		height: 120px;
		resize: none;
		margin-bottom: 50px;
		&:focus {
			border-bottom-color: #6a6a6a;
		}
	}
}
.banner-large-text {
	.shape-one {
		right: 0;
		top: 0;
		overflow: hidden;
		&:before {
			content: "D";
			font-family: gilroy-black;
			font-size: 400px;
			color: #fff;
			position: absolute;
			bottom: 68px;
			left: -33px;
			z-index: 1;
		}
	}
	.shape-two {
		top: 17%;
		left: 17%;
		animation: animationFramesOne 15s infinite linear alternate;
	}
	.shape-three {
		top: 12%;
		left: 44%;
		animation: animationFramesTwo 18s infinite linear alternate;
	}
	.shape-four {
		top: 18%;
		right: 7%;
		animation: animationFramesTwo 15s infinite linear alternate;
	}
	.shape-five {
		top: 50%;
		left: 4%;
		animation: animationFramesFive 20s infinite linear alternate;
	}
	.shape-six {
		top: 88%;
		left: 14%;
		animation: animationFramesFive 18s infinite linear alternate;
	}
	.shape-seven {
		top: 91%;
		left: 46%;
		animation: animationFramesOne 15s infinite linear alternate;
	}
	.shape-eight {
		top: 85%;
		right: 4%;
		animation: animationFramesTwo 18s infinite linear alternate;
	}
	.shape-nine {
		top: 55%;
		right: 3%;
		animation: animationFramesOne 15s infinite linear alternate;
	}
	.title {
		font-family: gilroy-bold !important;
		font-size: 120px;
		line-height: 128px;
		padding: 80px 200px 70px 0;
		span {
			&:before {
				content: '';
				position: absolute;
				width: 100px;
				height: 5px;
				background: var(--heading);
				right: -130px;
				top: 58%;
			}
		}
	}
	.sub-heading {
		font-size: 26px;
		color: var(--text-dark);
	}
}
.about-us-text.bg-color {
	background: linear-gradient(-180deg,#fff 0,#f8fcfe 100%);
}
.about-text-one {
	p {
		padding-bottom: 15px;
	}
	h6 {
		font-family: gilroy-semibold;
		font-size: 18px;
		color: var(--text-dark);
		text-transform: uppercase;
		letter-spacing: 1.5px;
		position: relative;
		padding-left: 40px;
		&:before {
			content: '';
			position: absolute;
			width: 23px;
			height: 2px;
			background: var(--text-dark);
			top: 45%;
			left: 0;
		}
	}
}
.about-us-text {
	.feature-block-two {
		margin-bottom: 30px;
	}
}
.about-feature {
	margin-top: -110px;
	z-index: 1;
}
.feature-block-two {
	padding: 55px 42px 75px;
	border-radius: 5px;
	background: #fff;
	box-shadow: 0 30px 70px 0 rgba(226,232,236,.5);
	.icon {
		margin: 0 auto;
	}
	h5 {
		font-family: gilroy-bold !important;
		font-size: 22px;
		color: var(--text-dark);
	}
}
.feature-block-three {
	position: relative;
	padding: 50px 40px 38px 70px;
	background: #fff;
	box-shadow: 0 30px 70px 0 rgba(223,227,234,.5);
	.icon-box {
		width: 55px;
		height: 55px;
		position: relative;
		border-radius: 50%;
		margin-top: 12px;
		&:before {
			content: '';
			position: absolute;
			top: -10px;
			left: -10px;
			right: -10px;
			bottom: -10px;
			background: inherit;
			border-radius: 50%;
			opacity: .15;
			-webkit-animation: hvr-ripple-out-two 1s linear infinite;
			animation: hvr-ripple-out-two 1s linear infinite;
		}
		img {
			margin: 0 auto;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.text {
		width: calc(100% - 55px);
		padding-left: 38px;
		h5 {
			font-family: gilroy-bold !important;
			font-size: 22px;
		}
		.read-more {
			font-size: 35px;
			color: var(--text-light);
		}
	}
	&:hover {
		.text {
			.read-more {
				color: var(--p-orange);
			}
		}
	}
}
.our-service-three {
	.shape-one {
		right: 0;
		top: 0;
	}
	.shape-two {
		width: 15px;
		height: 15px;
		top: 60px;
		left: 20%;
	}
	.shape-three {
		width: 30px;
		height: 30px;
		top: 38%;
		right: 5%;
	}
	.shape-four {
		width: 10px;
		height: 10px;
		bottom: 50px;
		right: 8%;
	}
	.shape-five {
		width: 15px;
		height: 15px;
		bottom: 40px;
		left: 8%;
	}
	.shape-six {
		width: 343px;
		height: 343px;
		top: 21%;
		left: 12%;
	}
}
.our-team {
	.img-box {
		img {
			width: 100%;
		}
	}
	.single-team-member {
		.info-meta {
			.name {
				font-size: 22px;
				padding-bottom: 5px;
				font-family: gilroy-semibold;
			}
			span {
				font-size: 18px;
			}
		}
	}
}
.team-business {
	.single-team-member {
		margin-bottom: 120px;
		.img-box {
			width: 220px;
		}
		.info-meta {
			padding-left: 40px;
			width: calc(100% - 220px);
			q {
				font-size: 21px;
				line-height: 25px;
				font-style: italic;
				color: #3d3d3d;
				display: block;
				padding: 30px 0 45px;
			}
		}
	}
}
.inner-banner.banner-bg {
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-position: center top;
	.opacity {
		background: rgba(0,0,0,.25);
		height: 100%;
	}
}
.inner-banner.banner-bg.bg-style-one {
	height: 670px;
	text-align: center;
	.opacity {
		padding: 300px 0 120px;
	}
	p {
		color: #fff;
		font-size: 24px;
		padding-bottom: 30px;
	}
	h2 {
		font-size: 58px;
		color: #fff;
		text-transform: uppercase;
	}
}
.inner-banner.banner-bg.bg-style-two {
	.opacity {
		padding: 270px 0 220px;
		text-align: center;
	}
	p {
		color: #fff;
		font-size: 24px;
		padding-bottom: 20px;
	}
	h2 {
		font-size: 58px;
		color: #fff;
	}
}
.text-inner-banner-one {
	padding: 150px 0 150px;
	text-align: center;
	background: #fff;
	z-index: 1;
	p {
		font-size: 24px;
	}
	h2 {
		font-family: gilroy-bold !important;
		font-size: 58px;
		text-transform: uppercase;
	}
	.theme-title-one {
		h2 {
			text-transform: inherit;
			margin-top: 5px;
		}
	}
	.sub-heading {
		font-size: 20px;
		line-height: 36px;
		width: 62%;
		display: inline-block;
	}
	.shape-one {
		width: 12px;
		height: 12px;
		top: 19%;
		left: 32%;
		animation: animationFramesTwo 15s infinite linear;
	}
	.shape-two {
		width: 12px;
		height: 12px;
		top: 32%;
		right: 32%;
		animation: animationFramesOne 25s infinite linear;
	}
	.shape-three {
		width: 24px;
		height: 24px;
		top: 42%;
		right: 8%;
		animation: animationFramesTwo 20s infinite linear;
	}
	.shape-four {
		width: 15px;
		height: 15px;
		bottom: -6px;
		right: 20%;
		animation: animationFramesFive 22s infinite linear;
	}
	.shape-five {
		width: 25px;
		height: 25px;
		top: 62%;
		left: 13%;
		animation: animationFramesFive 18s infinite linear;
	}
}
.faq-search-form {
	span {
		font-size: 18px;
		display: block;
		padding: 35px 0 30px;
	}
	.input-group {
		position: relative;
		margin: 0 auto;
		max-width: 43%;
		input {
			width: 100%;
			height: 60px;
			padding: 0 70px 0 30px;
			border: 1px solid rgba(0,0,0,.08);
			border-radius: 30px;
		}
		button {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 70px;
			background: 0 0;
			font-size: 20px;
			color: var(--text-light);
			border-radius: 0 30px 30px 0;
		}
	}
}
.service-modern-block.img-style {
	.wrapper {
		padding: 0 85px;
	}
	.row {
		margin: 0 -50px;
		[class*=col-] {
			padding: 0 50px;
		}
	}
}
.service-classic-block {
	.img-holder {
		position: relative;
		overflow: hidden;
		img {
			width: 100%;
			transition: all .5s ease-in-out;
		}
	}
	&:hover {
		.img-holder {
			img {
				transform: scale3d(1.1,1.1,1);
			}
		}
	}
	.text-holder {
		background: #fff;
		box-shadow: -15.045px 19.966px 40px 0 rgba(0,0,0,.04);
		padding: 55px 20px 45px 55px;
		position: relative;
		h3 {
			a {
				font-family: gilroy-semibold;
				font-size: 32px;
				color: var(--heading);
				margin-bottom: 10px;
			}
		}
		.icon-holder {
			width: 70px;
			height: 70px;
			border-radius: 50%;
			box-shadow: 0 15px 40px 0 rgba(189,189,189,.5);
			position: absolute;
			top: -35px;
			left: 55px;
			img {
				margin: 0 auto;
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}
.action-banner-one {
	background-color: #f9fbfe;
	background-image: url('../images/home/map.png');
	background-repeat: no-repeat;
	background-position: center;
	padding: 120px 0 130px;
	text-align: center;
	.main-title {
		font-size: 62px;
		line-height: 75px;
		margin-bottom: 40px;
	}
}
.action-banner-one.bg-white {
	background-color: #fff;
	background-image: none;
	padding-top: 150px;
}
.intro-text-block {
	background: #ffeceb;
	padding: 100px 0;
	h5 {
		font-family: gilroy-bold !important;
		font-size: 24px;
		line-height: 35px;
		span {
			display: block;
			font-family: gilroy-light;
		}
	}
	p {
		font-size: 18px;
		line-height: 32px;
		padding: 20px 0 35px;
	}
	h6 {
		font-family: gilroy-bold !important;
		font-size: 20px;
		position: relative;
		padding-left: 30px;
		color: #233d63;
		margin-bottom: 32px;
		span {
			font-size: 16px;
			color: var(--text-color);
		}
		&:before {
			content: '';
			position: absolute;
			width: 20px;
			height: 2px;
			background: #233d63;
			left: 0;
			top: 50%;
			margin-top: -1px;
		}
	}
}
.cbp-l-filters-alignCenter.filter-menu {
	margin-bottom: 60px;
	.cbp-filter-item {
		font-family: gilroy-semibold;
		font-size: 16px;
		color: var(--text-dark);
		line-height: 31px;
		text-transform: uppercase;
		border: 2px solid transparent;
		border-radius: 3px;
		padding: 0 12px;
		margin: 0 5px 10px;
	}
	.cbp-filter-item.cbp-filter-item-active {
		color: var(--p-rose);
		border-color: var(--p-rose);
	}
}
.cbp-l-filters-alignCenter {
	.cbp-filter-counter {
		z-index: 9;
	}
}
.our-portfolio {
	.cbp-l-caption-title {
		font-family: gilroy-semibold;
		font-size: 22px;
	}
	.cbp-l-caption-body {
		p {
			color: rgba(255,255,255,.6);
			padding-top: 5px;
		}
	}
	.cbp-l-caption-alignLeft {
		.cbp-l-caption-body {
			padding: 15px 20px;
		}
	}
	.cbp-l-caption-buttonLeft {
		background: var(--p-rose);
		color: #fff;
		text-transform: capitalize;
	}
	.cbp-l-caption-buttonRight {
		background: var(--p-rose);
		color: #fff;
		text-transform: capitalize;
	}
}
.project-details {
	.shape-one {
		width: 12px;
		height: 12px;
		top: 5%;
		left: 25%;
	}
	.shape-two {
		width: 12px;
		height: 12px;
		top: 6%;
		right: 20%;
	}
	.shape-three {
		width: 24px;
		height: 24px;
		top: 7%;
		right: 46%;
	}
	.shape-four {
		width: 25px;
		height: 25px;
		top: 14%;
		left: 6%;
	}
	.shape-five {
		width: 16px;
		height: 16px;
		top: 16%;
		right: 6%;
	}
	.project-container {
		max-width: 1030px;
		padding: 0 15px;
		margin: 0 auto;
	}
	.project-title-one {
		p {
			font-size: 18px;
			color: var(--text-light);
			text-transform: uppercase;
			letter-spacing: 1.5px;
		}
		h2 {
			font-size: 58px;
		}
	}
	.info-text-list {
		h6 {
			font-family: gilroy-bold !important;
			font-size: 18px;
			text-transform: uppercase;
			color: var(--text-dark);
		}
		p, li {
			font-size: 18px;
			padding-top: 10px;
			color: var(--text-color);
		}
	}
	.img-box {
		img {
			width: 100%;
		}
	}
	.img-description {
		.row {
			margin: 0 -50px;
			[class*=col-] {
				padding: 0 50px;
			}
		}
	}
	.des-title {
		font-size: 42px;
		line-height: 46px;
		span {
			display: inline-block;
			font-family: gilroy-ultralight;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				width: 80px;
				height: 3px;
				background: var(--text-dark);
				right: -90px;
				top: 57%;
			}
		}
	}
	strong {
		display: block;
		font-size: 24px;
		line-height: 30px;
		color: var(--text-dark);
		font-weight: 400;
	}
	.project-img-gallery {
		.img-box {
			margin-bottom: 45px;
		}
	}
	.title-two {
		font-family: gilroy-semibold;
		font-size: 24px;
		line-height: 32px;
		span {
			font-family: gilroy-ultralight;
		}
	}
	.title-three {
		font-size: 36px;
	}
	.pr-banner {
		background: url('../images/gallery/img1.jpg') no-repeat center;
		background-size: cover;
		background-attachment: fixed;
		margin-bottom: 155px;
		.opacity {
			background: rgba(0,0,0,.25);
			padding: 315px 0 325px;
		}
		.share-icon {
			position: absolute;
			right: 30px;
			top: 50%;
			transform: translateY(-50%) rotate(180deg);
			li {
				&:first-child {
					font-size: 16px;
					color: #fff;
					transform: rotate(-270deg);
					text-transform: uppercase;
					letter-spacing: 1.5px;
					margin: 0 0 40px -24px;
				}
				a {
					transform: rotate(180deg);
					color: #fff;
					margin-bottom: 15px;
				}
			}
		}
	}
	.project-title-two {
		p {
			font-size: 18px;
			color: #fff;
			text-transform: uppercase;
			letter-spacing: 1.5px;
		}
		h2 {
			font-family: gilroy-black;
			font-size: 72px;
			color: #fff;
			display: inline-block;
			line-height: 72px;
			position: relative;
			span {
				position: absolute;
				width: 220px;
				height: 4px;
				background: #fff;
				right: 0;
				bottom: 18%;
			}
		}
	}
}
.social-icon-two {
	display: inline-block;
	margin: 0 -4px;
	li {
		float: left;
		padding: 0 4px;
		a {
			width: 30px;
			height: 30px;
			display: block;
			line-height: 30px;
			text-align: center;
			color: #fff;
			border-radius: 50%;
		}
	}
}
.project-details.version-three {
	.theme-pagination-two {
		border-top: 1px solid rgba(0,0,0,.05);
		padding-top: 70px;
		margin-top: 80px;
	}
}
#project-img-carousel {
	.carousel-control-next {
		width: 45px;
		height: 45px;
		background: #fff;
		font-size: 26px;
		text-align: center;
		line-height: 45px;
		opacity: 1;
		color: var(--text-dark);
		top: 50%;
		margin-top: -22px;
		&:hover {
			color: var(--p-rose);
		}
	}
	.carousel-control-prev {
		width: 45px;
		height: 45px;
		background: #fff;
		font-size: 26px;
		text-align: center;
		line-height: 45px;
		opacity: 1;
		color: var(--text-dark);
		top: 50%;
		margin-top: -22px;
		&:hover {
			color: var(--p-rose);
		}
	}
}
.project-details.version-five {
	.title-two {
		border-bottom: 1px solid rgba(0,0,0,.05);
		padding: 30px 0 40px;
		margin-bottom: 40px;
	}
}
.our-blog {
	.blog-full-width-grid {
		max-width: 930px;
		margin: 0 auto;
		padding: 0 15px;
	}
	.blog-filter-nav {
		border-bottom: 2px solid #3b3b3b;
		margin-bottom: 80px;
		li {
			font-family: gilroy-semibold;
			float: left;
			width: 16.66666666%;
			text-align: center;
			margin: 15px 0 0;
			font-size: 16px;
			text-transform: uppercase;
			color: var(--text-dark);
			position: relative;
			padding-bottom: 20px;
			span {
				cursor: pointer;
			}
			&:before {
				content: '';
				position: absolute;
				width: 21px;
				height: 21px;
				background: #fff;
				border-top: 2px solid #3b3b3b;
				border-right: 2px solid #3b3b3b;
				transform: rotate(135deg);
				bottom: -8px;
				left: 50%;
				margin-left: -10px;
				z-index: 1;
				opacity: 0;
				transition: all .3s ease-in-out;
			}
		}
		li.is-checked {
			color: var(--p-rose);
			&:before {
				opacity: 1;
				bottom: -12px;
			}
		}
	}
	.masnory-blog-wrapper {
		margin: 0 -15px;
		.grid-sizer {
			width: 33.333333%;
			padding: 0 15px;
		}
		.isotop-item {
			width: 33.333333%;
			padding: 0 15px;
		}
	}
	.post-data {
		.date {
			font-size: 20px;
			color: #b5b5b5;
			text-transform: capitalize;
			&:hover {
				color: #363636;
				text-decoration: underline;
			}
		}
	}
}
.version-four {
	.blog-post-block-two {
		.post {
			h4 {
				a {
					font-size: 32px;
				}
			}
		}
	}
}
.theme-form-style-four {
	input {
		border: 1px solid #dcdcdc;
		width: 100%;
		max-width: 100%;
		margin-bottom: 35px;
		color: #989ca2;
		height: 60px;
		padding: 0 25px;
		&:focus {
			border-color: #383838;
		}
	}
	textarea {
		border: 1px solid #dcdcdc;
		width: 100%;
		max-width: 100%;
		margin-bottom: 35px;
		color: #989ca2;
		height: 260px;
		max-height: 240px;
		padding: 25px;
		&:focus {
			border-color: #383838;
		}
	}
	&::placeholder {
		color: #989ca2;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: #989ca2;
	}
	&::-ms-input-placeholder {
		color: #989ca2;
	}
}
.blog-details.blog-details-fg {
	.user-comment-area {
		.single-comment {
			border-bottom: none;
			padding-bottom: 30px;
		}
	}
}
.theme-form-style-three {
	input {
		width: 100%;
		border: none;
		border-bottom: 2px solid #383838;
		background: 0 0;
		margin-bottom: 35px;
		padding: 0;
		height: 60px;
	}
	textarea {
		width: 100%;
		border: none;
		border-bottom: 2px solid #383838;
		background: 0 0;
		margin-bottom: 35px;
		padding: 25px 0;
		height: 200px;
	}
	&::placeholder {
		color: #3b3b3b;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: #3b3b3b;
	}
	&::-ms-input-placeholder {
		color: #3b3b3b;
	}
}
.user-comment-area {
	.single-comment {
		padding-bottom: 50px;
		&:last-child {
			padding-bottom: 0;
			padding-bottom: 0;
		}
		.user-img {
			width: 60px;
			height: 60px;
			border-radius: 50%;
		}
		.user-comment-data {
			padding-left: 30px;
			position: relative;
			.name {
				font-family: gilroy-semibold;
				font-size: 20px;
				color: #3e3e3e;
				padding-bottom: 5px;
			}
			.rating {
				li {
					display: inline-block;
					display: inline-block;
					a {
						font-size: 15px;
						color: #fbc134;
						margin-right: 1px;
						font-size: 15px;
						color: #fbc134;
						margin-right: 1px;
					}
				}
			}
			p {
				padding-top: 15px;
			}
		}
	}
}
.eCommerce-side-menu.show-menu {
	opacity: 1;
	transform: scale(1);
	.main-menu-list {
		>ul {
			>li {
				&:nth-child(1) {
					transition-delay: .2s;
				}
				&:nth-child(2) {
					transition-delay: .3s;
				}
				&:nth-child(3) {
					transition-delay: .4s;
				}
				&:nth-child(4) {
					transition-delay: .5s;
				}
				&:nth-child(5) {
					transition-delay: .6s;
				}
				opacity: 1;
				transform: translateX(0);
			}
		}
	}
}
.main-menu-list {
	ul {
		.sub-menu {
			display: none;
			transition: all .3s ease-in-out;
		}
		.sub-menu.show {
			display: block;
		}
	}
}
.product-filter-area {
	position: relative;
	padding-bottom: 40px;
	.display-item-filter {
		font-size: 17px;
		color: rgba(79,79,79,.7);
	}
	.large-filter-content {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 70px;
		width: 100%;
		background: #fff;
		border: 1px solid #f4f4f4;
		padding: 50px 70px 70px;
		box-shadow: 0 50px 100px 0 rgba(207,215,224,.2);
		transform: scale(1,0);
		transform-origin: 0 0;
		opacity: 0;
		transition: all .3s ease-in-out;
	}
	.large-filter-content.show-content {
		opacity: 1;
		transform: scale(1);
	}
}
.filter-dropdown-holder {
	.filter-button {
		background: 0 0;
		font-size: 20px;
		color: #2e2e2e;
		padding-left: 45px;
		position: relative;
		line-height: 30px;
		margin-right: 55px;
		.icon {
			width: 30px;
			height: 30px;
			background: #3a3a3a;
			border-radius: 50%;
			position: absolute;
			left: 0;
			top: 0;
			padding-top: 8px;
			span {
				display: block;
				height: 2px;
				background: #fff;
				margin: 2px auto;
				transition: all .3s ease-in-out;
				&:nth-child(1) {
					width: 17px;
				}
				&:nth-child(2) {
					width: 11px;
				}
				&:nth-child(3) {
					width: 5px;
				}
			}
		}
	}
	.filter-button.open {
		.icon {
			span {
				&:nth-child(1) {
					width: 5px;
				}
				&:nth-child(2) {
					width: 11px;
				}
				&:nth-child(3) {
					width: 17px;
				}
			}
		}
	}
}
.promo-code-section {
	background: #ffd78a;
	padding: 25px 15px;
	text-align: center;
	p {
		font-size: 18px;
		color: #1e1e1e;
		span {
			font-weight: 500;
		}
	}
}
.realated-product {
	.title {
		font-size: 36px;
		padding-bottom: 55px;
	}
	.owl-theme {
		.owl-nav {
			[class*=owl-] {
				background: 0 0;
				padding: 0;
				font-size: 32px;
				margin: 0 0 0 15px;
				color: #cacaca;
				&:hover {
					color: #000;
				}
			}
			position: absolute;
			top: -100px;
			right: 0;
		}
	}
}
.realated-product.product-showcase {
	.single-product-case {
		margin: 0;
	}
}
.cart-section {
	.main-container {
		max-width: 1260px;
		padding: 0 15px;
		margin: 0 auto;
	}
	.cart-footer {
		border-top: 2px solid #545454;
		margin-top: 28px;
		padding-top: 35px;
		.coupon-form {
			padding-bottom: 120px;
			input {
				width: 240px;
				height: 50px;
				border: none;
				border-bottom: 2px solid #545454;
				font-size: 16px;
				color: #c4c4c4;
				margin-right: 30px;
			}
			&::placeholder {
				color: #c4c4c4;
				opacity: 1;
			}
			&:-ms-input-placeholder {
				color: #c4c4c4;
			}
			&::-ms-input-placeholder {
				color: #c4c4c4;
			}
		}
		.cart-total-section {
			text-align: right;
			padding-right: 82px;
			.checkout-process {
				margin-top: 24px;
			}
		}
		.cart-total-table {
			tr {
				th {
					font-size: 18px;
					color: var(--text-light);
					font-weight: 400;
					padding-right: 40px;
					padding-bottom: 12px;
				}
				td {
					font-family: gilroy-semibold;
					font-size: 24px;
					color: var(--text-dark);
					padding-bottom: 12px;
				}
			}
		}
	}
}
.cart-list-form {
	position: relative;
	table {
		margin: 0;
	}
	.table {
		th {
			border: none;
			text-align: center;
			padding: 0 0 50px;
			text-align: center;
			text-transform: uppercase;
			color: var(--text-dark);
			font-size: 15px;
			&:first-child {
				font-family: gilroy-bold !important;
				letter-spacing: 1.2px;
				text-align: left;
			}
		}
		tbody {
			td {
				padding: 0 0 70px;
				border: none;
				vertical-align: middle;
				text-align: center;
			}
		}
		.product-thumbnails {
			width: 85px;
			img {
				max-width: none;
			}
		}
		.product-img {
			display: block;
		}
		.product-info {
			padding-left: 30px;
			text-align: left;
			.product-name {
				font-family: gilroy-semibold;
				font-size: 20px;
				color: #1f1f1f;
				&:hover {
					color: var(--p-rose);
				}
			}
			.serial {
				font-size: 16px;
				color: rgba(31,31,31,.33);
				padding-bottom: 10px;
			}
			ul {
				li {
					display: inline-block;
					font-size: 16px;
					color: #404040;
					padding-right: 15px;
				}
			}
		}
		.price {
			font-size: 20px;
			color: #1d1d1d;
		}
		.quantity {
			li {
				display: inline-block;
				line-height: 40px;
				max-height: 40px;
				.switcher {
					font-size: 24px;
					color: var(--text-dark);
					cursor: pointer;
				}
			}
			.product-value {
				font-size: 20px;
				color: #1d1d1d;
				max-width: 45px;
				background: 0 0;
				border: none;
				text-align: center;
				padding-left: 12px;
			}
		}
		.remove-product {
			color: var(--text-light);
			font-size: 22px;
			&:hover {
				color: red;
			}
		}
	}
}
.checkout-form {
	.main-title {
		font-size: 32px;
		padding-bottom: 55px;
	}
	.single-input-wrapper {
		display: block;
		width: 100%;
		height: 40px;
		font-size: 16px;
		color: #1a1a1a;
		border: none;
		border-bottom: 2px solid #e5e5e5;
		margin-bottom: 55px;
		&:focus {
			border-bottom-color: #545454;
		}
	}
	&::placeholder {
		color: #1a1a1a;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: #1a1a1a;
	}
	&::-ms-input-placeholder {
		color: #1a1a1a;
	}
	.selectize-input {
		width: 100%;
		line-height: 40px;
		border-radius: 0;
		border: none;
		border-bottom: 2px solid #e5e5e5;
		box-shadow: none;
		outline: 0;
		padding: 0 15px 0 0;
		.item {
			font-size: 16px;
			color: #1a1a1a;
		}
		input {
			font-size: 16px;
			color: #1a1a1a;
		}
	}
	.selectize-control {
		margin-bottom: 50px;
	}
	.selectize-dropdown {
		background: #fff;
		border: 1px solid #e9e9e9;
		border-top: none;
		box-shadow: 0 50px 100px 0 rgba(229,232,235,.2);
		cursor: pointer;
		.option {
			font-size: 14px;
			color: #1a1a1a;
			line-height: 22px;
			&:hover {
				color: #fff;
				background: #1a1a1a;
			}
		}
		.active {
			color: #fff;
			background: #1a1a1a;
		}
	}
	.selectize-control.single {
		.selectize-input {
			&:after {
				right: 8px;
			}
		}
	}
	.checkbox-list {
		li {
			label {
				position: relative;
				font-weight: 500;
				font-size: 16px;
				line-height: 15px;
				padding-left: 30px;
				color: var(--text-dark);
				cursor: pointer;
				margin: 0 0 12px;
				&:before {
					content: '';
					width: 15px;
					height: 15px;
					line-height: 15px;
					border-radius: 2px;
					border: 1px solid #d5d5d5;
					font-size: 10px;
					text-align: center;
					position: absolute;
					left: 0;
					top: -1px;
				}
			}
			input[type=checkbox] {
				display: none;
				&:checked+label {
					&:before {
						content: "\f107";
						font-family: Flaticon;
						background: #373737;
						color: #fff;
						border-color: #373737;
					}
				}
			}
		}
		padding-bottom: 55px;
	}
	.other-note-area {
		p {
			font-family: gilroy-semibold;
			font-size: 16px;
			color: var(--text-dark);
			padding-bottom: 8px;
		}
		textarea {
			width: 100%;
			border: 1px solid #e5e5e5;
			padding: 15px;
			resize: none;
			height: 145px;
			&:focus {
				border-color: #545454;
			}
		}
	}
	.order-confirm-sheet {
		padding-left: 60px;
		.order-review {
			border: 1px solid #e5e5e5;
			padding: 50px 40px;
			.product-review {
				width: 100%;
				tbody {
					th {
						span {
							font-family: gilroy-semibold;
							font-size: 18px;
							color: var(--text-dark);
							font-weight: 400;
						}
						padding-bottom: 25px;
					}
					td {
						font-size: 18px;
						color: #1d1d1d;
						text-align: right;
						padding-bottom: 25px;
					}
				}
				tfoot {
					th {
						font-family: gilroy-semibold;
						font-size: 16px;
						text-transform: uppercase;
						color: var(--text-dark);
						font-weight: 400;
						border-top: 1px solid #e9e9e9;
						padding-top: 15px;
					}
					td {
						font-family: gilroy-semibold;
						text-align: right;
						font-size: 24px;
						color: var(--text-dark);
						border-top: 1px solid #e9e9e9;
						padding-top: 15px;
					}
				}
			}
			.payment-list {
				li {
					padding: 0 0 5px 30px;
					position: relative;
					p {
						font-size: 15px;
						line-height: 22px;
						padding-bottom: 15px;
					}
					label {
						position: relative;
						font-family: gilroy-semibold;
						font-size: 18px;
						line-height: 15px;
						color: var(--text-dark);
						cursor: pointer;
						margin: 0 0 15px;
						&:before {
							content: '';
							width: 15px;
							height: 15px;
							line-height: 12px;
							border-radius: 50%;
							border: 1px solid #d5d5d5;
							font-size: 10px;
							text-align: center;
							position: absolute;
							left: -30px;
							top: -1px;
						}
					}
					input[type=radio] {
						position: absolute;
						opacity: 0;
						z-index: 1;
						width: 100%;
						height: 100%;
						left: 0;
					}
					input {
						&:checked+label {
							&:before {
								content: "";
								font-family: font-awesome;
								background: #373737;
								color: #fff;
								border-color: #373737;
							}
						}
					}
				}
				padding: 30px 0 15px;
				border-bottom: 1px solid #e9e9e9;
			}
		}
		.policy-text {
			font-size: 15px;
			line-height: 22px;
			padding: 25px 0 20px;
		}
		.agreement-checkbox {
			label {
				position: relative;
				font-size: 16px;
				color: var(--text-dark);
				cursor: pointer;
				padding-left: 30px;
				margin-bottom: 35px;
				&:before {
					content: '';
					width: 15px;
					height: 15px;
					line-height: 12px;
					border-radius: 2px;
					border: 1px solid #d5d5d5;
					font-size: 10px;
					text-align: center;
					position: absolute;
					left: 0;
					top: 7px;
				}
			}
			input[type=checkbox] {
				display: none;
				&:checked+label {
					&:before {
						content: "";
						font-family: font-awesome;
						background: #373737;
						color: #fff;
						border-color: #373737;
					}
				}
			}
		}
		.theme-button-three {
			width: 100%;
		}
	}
	.credit-card-form {
		margin-top: 25px;
		display: none;
		h6 {
			font-family: gilroy-bold !important;
			font-size: 15px;
			padding-bottom: 5px;
		}
		input {
			width: 100%;
			height: 40px;
			font-size: 14px;
			border: 1px solid rgba(0,0,0,.07);
			padding: 0 10px;
			border-radius: 3px;
			margin-bottom: 30px;
		}
		span {
			padding: 0 5px;
			margin-bottom: 30px;
		}
	}
}
.checkout-toggle-area {
	p {
		padding-bottom: 5px;
		button {
			font-family: gilroy-semibold;
			color: var(--text-dark);
			font-size: 17px;
			background: 0 0;
			display: inline-block;
			text-decoration: underline;
		}
	}
	form {
		input {
			width: 100%;
			height: 55px;
			border: 1px solid #d5d5d5;
			border-radius: 5px;
			padding: 0 30px;
			margin-bottom: 20px;
			&:focus {
				border-color: #777;
			}
		}
		.lost-passw {
			color: #636067;
			font-size: 16px;
			margin: 12px 0 35px;
		}
		button {
			font-size: 15px;
		}
		p {
			padding-top: 30px;
		}
	}
}
#contact-form {
	.form-group {
		margin: 0;
		position: relative;
		.help-block {
			position: absolute;
			left: 0;
			bottom: 3px;
			font-size: 15px;
			line-height: 20px;
			color: #fff;
			padding: 0 15px;
			border-radius: 3px;
			z-index: 1;
			background: var(--rose-gr-bg);
			box-shadow: 0 10px 25px 0 rgba(123,147,171,.15);
			li {
				position: relative;
				&:before {
					content: '';
					font-family: font-awesome;
					position: absolute;
					top: -12px;
					left: 0;
					color: var(--p-rose);
				}
			}
		}
	}
}
.contact-us-section {
	.theme-form-style-two {
		padding: 0;
		box-shadow: none;
		textarea {
			margin-bottom: 30px;
		}
	}
	.contact-info {
		p {
			font-size: 20px;
		}
		.call {
			font-size: 27px;
			font-weight: 500;
			color: var(--text-dark);
			display: block;
			margin-bottom: 40px;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.map-canvas {
	width: 100%;
	height: 100%;
}
#google-map-one {
	height: 405px;
}
#google-map-two {
	height: 620px;
}
.faq-page {
	.faq-page-title {
		font-size: 32px;
	}
	.faq-search-form {
		height: 60px;
		position: relative;
		input {
			width: 500px;
			height: 100%;
			border: 1px solid #e1e1e1;
			padding: 0 70px 0 30px;
			font-size: 18px;
			color: #a6a6a6;
			font-style: italic;
			&:focus {
				border-color: #313131;
			}
		}
		button {
			position: absolute;
			width: 70px;
			top: 0;
			right: 0;
			bottom: 0;
			background: 0 0;
			color: #b1b1b1;
		}
	}
	.submit-faq {
		background: #f6f8fc;
		padding: 120px 0;
		margin-top: 140px;
		.faq-page-title {
			padding-bottom: 75px;
		}
	}
}
.theme-form-style-five {
	input {
		width: 100%;
		height: 60px;
		padding: 0 15px 0 30px;
		color: #989ca2;
		border: 1px solid #e7e7e7;
		margin-bottom: 30px;
		&:focus {
			border-color: #212121;
		}
	}
	&::placeholder {
		color: #989ca2;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: #989ca2;
	}
	&::-ms-input-placeholder {
		color: #989ca2;
	}
	textarea {
		width: 100%;
		height: 290px;
		max-height: 290px;
		padding: 15px 30px;
		color: #989ca2;
		resize: none;
		border: 1px solid #e7e7e7;
		margin-bottom: 30px;
		&:focus {
			border-color: #212121;
		}
	}
	button {
		display: block;
		width: 100%;
		height: 60px;
		border-width: 2px;
		border-style: solid;
		font-size: 18px;
		color: #fff;
	}
}
.faq-tab-wrapper {
	.faq-panel {
		.panel {
			background: #fff;
			box-shadow: 0 10px 14px 0 rgba(90,111,155,.05);
			margin-bottom: 30px;
			.panel-heading {
				.panel-title {
					a {
						font-family: gilroy-semibold;
						display: block;
						padding: 25px 10px 25px 65px;
						font-size: 20px;
						line-height: 32px;
						color: var(--text-dark);
						position: relative;
						&:before {
							content: '+';
							position: absolute;
							left: 35px;
							top: 50%;
							transform: translateY(-50%);
							font-size: 30px;
							color: #464646;
						}
					}
				}
			}
			.panel-heading.active-panel {
				.panel-title {
					a {
						&:before {
							content: '-';
							color: var(--p-rose);
						}
					}
				}
			}
			.panel-body {
				p {
					line-height: 30px;
					padding: 0 90px 38px 65px;
				}
			}
		}
	}
}
.help-articles {
	background: #f6f8fc;
	padding: 120px 0 100px;
	.single-help-box {
		background: #fff;
		margin-bottom: 30px;
		transition: all .3s ease-in-out;
		a {
			display: block;
			padding: 35px 25px;
			min-height: 210px;
		}
		h4 {
			font-family: gilroy-semibold;
			font-size: 20px;
			margin-bottom: 15px;
		}
		p {
			font-size: 16px;
			color: var(--text-color);
		}
	}
}
.error-page {
	background: url('../images/home/404.svg') no-repeat center bottom;
	background-size: cover;
	height: 100vh;
	padding: 0 15px;
	text-align: center;
	position: relative;
	>div {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
	h2 {
		font-size: 70px;
	}
	p {
		font-size: 24px;
		line-height: 34px;
		padding: 28px 0 45px;
	}
}
.signUp-illustration {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 38%;
	background: #f8faff;
	z-index: -1;
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50% ,-50%);
	}
}
.signUp-page {
	position: relative;
	min-height: 100vh;
	z-index: 5;
	.shape-wrapper {
		span {
			position: absolute;
			display: block;
			width: 26px;
			height: 26px;
			border-radius: 50%;
			&:nth-child(1) {
				background: #ff9898;
				top: 10%;
				left: 20%;
				animation: scale-upOne 3.9s alternate infinite ease-in;
			}
			&:nth-child(2) {
				background: #abafff;
				top: 24%;
				right: 7%;
				animation: scale-upOne 6s alternate infinite ease-in;
			}
			&:nth-child(3) {
				background: #67f5e2;
				top: 68%;
				right: 62%;
				margin-right: -13px;
				animation: scale-upOne 5s alternate infinite ease-in;
			}
			&:nth-child(4) {
				background: #ffb3b3;
				bottom: 15%;
				right: 25%;
				animation: scale-upOne 4s alternate infinite ease-in;
			}
			&:nth-child(5) {
				background: #ffe281;
				bottom: 14%;
				left: 5%;
				animation: scale-upOne 5s alternate infinite ease-in;
			}
		}
	}
	.title-area {
		p {
			font-size: 18px;
			padding: 10px 0 35px;
		}
	}
	.social-icon-wrapper {
		a {
			display: block;
			text-align: center;
			line-height: 55px;
			font-size: 20px;
			color: #fff;
			margin-top: 10px;
			.fa {
				font-size: 18px;
				margin-right: 5px;
				vertical-align: 1px;
			}
		}
		.gmail {
			background: #d93025;
		}
		.facebook {
			background: #4267b2;
		}
	}
	.or-text {
		position: relative;
		text-align: center;
		margin: 50px 0 45px;
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			background: #e2e2e2;
			left: 0;
			top: 14px;
			z-index: -1;
		}
		span {
			display: inline-block;
			font-size: 13px;
			text-transform: uppercase;
			color: #454545;
			background: #fff;
			width: 50px;
		}
	}
	form {
		&::placeholder {
			color: #bcbcbc;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			color: #bcbcbc;
		}
		&::-ms-input-placeholder {
			color: #bcbcbc;
		}
		.input-group {
			position: relative;
			z-index: 1;
			margin-bottom: 35px;
			input {
				width: 100%;
				border: 1px solid #e0e0e0;
				border-radius: 5px;
				background: 0 0;
				height: 60px;
				font-size: 18px;
				color: #454545;
				position: relative;
				padding: 0 20px;
				&:focus~label {
					top: -12px;
				}
				&:valid~label {
					top: -12px;
				}
				&:focus {
					border-color: #393939;
					padding-top: 18px;
				}
				&:valid {
					padding-top: 18px;
				}
			}
			label {
				font-weight: 400;
				font-size: 15px;
				line-height: 60px;
				position: absolute;
				left: 20px;
				top: 0;
				z-index: -1;
				margin: 0;
				transition: all .3s ease-in-out;
			}
		}
	}
	.selectize-control {
		width: 100%;
	}
	.input-group {
		.selectize-input {
			width: 100%;
			line-height: 60px;
			height: 60px;
			border-radius: 5px;
			border: none;
			border: 1px solid #e0e0e0;
			box-shadow: none;
			outline: 0;
			padding: 0 20px;
			background: 0 0;
			input {
				font-size: 15px;
			}
		}
	}
	.selectize-input {
		.item {
			font-size: 18px;
			color: #454545;
		}
	}
	.selectize-dropdown {
		background: #fff;
		border: 1px solid #e9e9e9;
		border-top: none;
		box-shadow: 0 50px 100px 0 rgba(229,232,235,.2);
		cursor: pointer;
		.option {
			font-size: 14px;
			color: #1a1a1a;
			line-height: 22px;
			&:hover {
				color: #fff;
				background: #1a1a1a;
			}
		}
		.active {
			color: #fff;
			background: #1a1a1a;
		}
	}
	.selectize-control.single {
		.selectize-input {
			&:after {
				right: 8px;
			}
		}
	}
	.acType-content {
		h4 {
			font-size: 28px;
		}
		p {
			padding: 8px 0 25px;
		}
		.acType-list {
			margin: 0 -22px;
			li {
				float: left;
				padding: 0 22px;
				>div {
					position: relative;
					input {
						position: absolute;
						opacity: 0;
						z-index: 1;
						width: 100%;
						height: 100%;
						cursor: pointer;
						&:checked+label {
							&:before {
								border-color: #ff3a46;
							}
							color: rgba(47,52,62,1);
						}
					}
					label {
						position: relative;
						font-size: 16px;
						line-height: 15px;
						color: rgba(47,52,62,.8);
						cursor: pointer;
						padding-left: 25px;
						margin: 0 0 15px;
						transition: all .1s ease-in-out;
						&:before {
							content: '';
							width: 14px;
							height: 14px;
							border-radius: 50%;
							border: 2px solid #d6d6d6;
							position: absolute;
							left: 0;
							top: 0;
							transition: all .1s ease-in-out;
						}
					}
				}
			}
		}
		border-bottom: 1px solid #e0e0e0;
		padding-bottom: 65px;
	}
	.agreement-checkbox {
		label {
			position: relative;
			font-size: 16px;
			color: rgba(47,52,62,.6);
			cursor: pointer;
			padding-left: 22px;
			margin: 30px 0 35px;
			transition: all .1s ease-in-out;
			&:before {
				content: '';
				width: 12px;
				height: 12px;
				line-height: 10px;
				border-radius: 2px;
				border: 1px solid #d5d5d5;
				font-size: 8px;
				text-align: center;
				position: absolute;
				left: 0;
				top: 8px;
				transition: all .1s ease-in-out;
			}
		}
		input[type=checkbox] {
			display: none;
			&:checked+label {
				&:before {
					content: "";
					font-family: font-awesome;
					background: #373737;
					color: #fff;
					border-color: #373737;
				}
				color: #373737;
			}
		}
	}
}
.signUp-minimal {
	padding-left: 15px;
	padding-right: 15px;
	.sign-up-form-wrapper {
		max-width: 650px;
		margin: 0 auto;
		border: 1px solid #e0e0e0;
		padding: 70px 80px 60px;
		position: relative;
	}
	.signin-form-wrapper {
		max-width: 520px;
		margin: 0 auto;
		border: 1px solid #e0e0e0;
		padding: 70px 65px 35px;
		position: relative;
		.signUp-text {
			font-size: 17px;
			padding-top: 35px;
			a {
				color: var(--p-rose);
			}
		}
	}
}
.signUp-page.signUp-minimal {
	.shape-wrapper {
		span {
			&:nth-child(3) {
				right: 88%;
			}
		}
	}
	.agreement-checkbox {
		label {
			margin-top: 0;
		}
	}
	form {
		.theme-btn {
			width: 100%;
			border-radius: 5px;
		}
	}
}
.signin-form-wrapper {
	.title-area {
		margin-bottom: 50px;
	}
	.signUp-text {
		padding-top: 40px;
		a {
			color: var(--p-rose);
		}
	}
}
.signUp-standard {
	.signin-form-wrapper {
		#login-form {
			.input-group {
				input {
					border: none;
					border-radius: 0;
					border-bottom: 1px solid #e0e0e0;
					padding-left: 0;
				}
				label {
					left: 0;
				}
			}
			padding-right: 170px;
		}
	}
}
#login-form {
	.agreement-checkbox {
		label {
			margin: 0;
		}
		a {
			font-size: 16px;
			color: rgba(47,52,62,.6);
			&:hover {
				color: #212121;
			}
		}
		margin-bottom: 55px;
	}
	button {
		text-transform: uppercase;
	}
}
@media screen and (max-width:500px) {
	.ctn-preloader {
		.animation-preloader {
			.txt-loading {
				.letters-loading {
					font-size: 65px;
					letter-spacing: 10px;
				}
			}
		}
	}
}
.home-slider{
	margin-top: -150px;
}
.navbar-item{
	img{
		max-height:50px;
	}
}
.core-features{
	z-index:1;
	.feature-block-one {
		.icon{
			display:block;
			margin-bottom: 50px;
		}
	}
}
.about-us{
	z-index:1;
}
.our-services{
	z-index: 1;
}
section.home-slider, section.core-features, section.about-us, section.our-services, section.our-testimonials{
	padding: 0px !important;
}
.our-testimonials{
	z-index: 1;
}
.our-consultation{
	z-index: 1;
}
.subscribe{
	z-index: 1;
	padding:0px;
}
.navbar{
	.container{
		.navbar-brand{
			@media(max-width:767px){
				width: 100%;
				display: flex;
				align-items: center;
			}
			.navbar-burger{
				@media(max-width:767px){
					
				}
			}
		}
	}
	z-index: 29 !important;
	background-color:transparent !important;
}
.head-btn{
	margin-left: 20px;
}
.main-illustration{
	@media(max-width: 767px){
		display: none;
	}
}
body{
	.theme-btn{
		font-size:15px;
		min-width: 150px;
	}
}
.project-one{
	// background-image: url('https://embtel.ca/desievent/images/projects/recruitfy.png');
	background-image: url('../images/projects/jrroofer.jpg');
}
.project-two{
	// background-image: url('https://embtel.ca/desievent/images/projects/tectonic.jpg');
	background-image: url('../images/projects/mmazel.jpg');
}
.project-three{
	// background-image: url('https://embtel.ca/desievent/images/projects/tob.jpg');
	background-image: url('../images/projects/fbotanical.jpg');
}
.project-four{
	// background-image: url('https://embtel.ca/desievent/images/projects/pet.jpg');
	background-image: url('../images/projects/eezypost.jpg');
}
.project-five{
	// background-image: url('https://embtel.ca/desievent/images/projects/ipark.jpg');
	background-image: url('../images/projects/gogreen.jpg');
}
.project-six{
	// background-image: url('https://embtel.ca/desievent/images/projects/power.png');
	background-image: url('../images/projects/baytechrecovery.jpg');
}
.single-project{
	
	min-height: 300px;
	background-size:cover;
	background-position: top center;
	background-repeat: no-repeat;
	box-shadow: 10px 10px 30px rgba(0,0,0,.1);
	border-radius: 10px;
	transition: background-position 3.5s ease-out 0s;
}
.single-project:hover{
	background-position: bottom center;
}
.project-details{
	text-align: center;
}
.logos{
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	.mr-10{
		margin-right: 10px;
	}
}
// Modal
.modal.show {
	.modal-dialog {
		transform: translate(0, 0);
		
	}
    opacity: 1;
}
@media(min-width:560px){
	.modal-dialog {
		max-width: none !important;
	}
}
.modal-open{ 
	.modal {
		overflow-x: hidden;
		overflow-y: auto;
	}
}
.modal-content{
	@media(max-width:767px){
		padding:0px;
		max-height:none !important;
	}
}
.modal {
	.modal-content {
		
		padding:30px;
		overflow: hidden;
		border-radius: 0;
		border: none;
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		pointer-events: auto;
		background-color: transparent;
		background-clip: padding-box;
		border-radius: 0;
		outline: 0;
		.text-simple-01 {
			font-weight: 500;
			font-size: 20px;
			line-height: 21px;
			color: #ffffff;
			margin-top: 0px;
		}
		.text-simple-02 {
			@media(max-width:767px){
				font-size: 70px;
				line-height: 60px;		
			}
			font-family: 'Bangers';
			font-weight: 500;
			font-size: 150px;
			line-height: 130px;
			color: #ffffff;
			margin-top: 27px;
		}
		.text-simple-03 {
			font-weight: 400;
			font-size: 30px;
			line-height: 36px;
			color: #ffffff;
			margin-top: 17px;
		}
		.text-simple-04 {
			@media(max-width:767px){
				font-size: 40px;
				line-height: 50px;		
			}
			.price-tag{
				@media(max-width:767px){
					font-size: 60px;
					
				}
				font-size: 90px;	
				font-weight: 500;
					
			}
			font-weight: 400;
			font-size: 60px;
			line-height: 76px;
			color: #f73c82;
			margin-top: 27px;
		}
		.text-simple-05 {
			@media(max-width:767px){
				font-size: 20px;
				line-height: 19px;		
			}
			font-weight: 400;
			font-size: 30px;
			line-height: 29px;
			color: #f73c82;
			margin-top: 0px;
		}
		.dismiss-text{
			color: #fff;
			cursor:pointer;	
		}
	}
    text-align: center;
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
	z-index: 30;
	background-color: rgba(0,0,0,0.9);
		justify-content: center;
		align-items: center;
    -webkit-overflow-scrolling: touch;
	outline: none;
	transition: opacity 0.15s linear;
}
.para{
	margin:30px 0px;
	p{
		font-size: 18px;
		color: #929292;
		line-height: 1.7;
		margin:20px 0px;
	}
}
#why-website{
	margin-top: -100px;
}
.german{
	.service-title{
		@media(min-width:767px){
			margin-top: -150px;
		}
	}
}
a.navbar-item {
	&:focus {
		background-color: rgba(247, 60, 130, 0.06);
		color: #f73c82;
	}
	&:focus-within {
		background-color: rgba(247, 60, 130, 0.06);
		color: #f73c82;
	}
	&:hover {
		background-color: rgba(247, 60, 130, 0.06);
		color: #f73c82;
	}
}
a.navbar-item.is-active {
	background-color: rgba(247, 60, 130, 0.06);
	color: #f73c82;
}
.navbar-link {
	&:focus {
		background-color: rgba(247, 60, 130, 0.06);
		color: #f73c82;
	}
	&:focus-within {
		background-color: rgba(247, 60, 130, 0.06);
		color: #f73c82;
	}
	&:hover {
		background-color: rgba(247, 60, 130, 0.06);
		color: #f73c82;
	}
}
.navbar-link.is-active {
	background-color: rgba(247, 60, 130, 0.06);
	color: #f73c82;
}
/* Under Construction Page */
.main-banner {
	position: relative;
	z-index: 1;
	height: 100vh;
	overflow: hidden;
	background-color: #ffffff;
	margin-top: -100px;
	&::before {
		content: '';
		position: absolute;
		right: -200px;
		top: 0;
		width: 900px;
		height: 100%;
		background-image: url(./../images/1-svg-1.svg);
		background-position: bottom right;
		background-size: cover;
		background-repeat: no-repeat;
	}
}
.main-banner-content {
	max-width: 640px;
	margin-left: auto;
	margin-right: auto;
	
	h1 {
		margin-bottom: 0;
		font-size: 55px;
		// font-weight: 700;
	}
	p {
		color: #666666;
		line-height: 1.8;
		max-width: 600px;
		margin-top: 15px;
		margin-bottom: 15px;
	}
	
}
.main-banner-image {
	position: relative;
	text-align: center;
	left: -75px;
	bottom: -10px;
}
.main-banner-content{
	.newsletter-section{
		.main-wrapper{
			form{
				max-width: 90%;
				margin: 0px;
			}
		}
	}
}
.under-construction{
	.navbar{
		.menu-item{
			display: none;
		}
	}
	.footer-main{
		display: none !important;
	}
}
.footer-area {
	position: absolute;
	
	bottom: 0;
	width: 100%;
	height: auto;
	z-index: 1;
	padding-top: 30px;
	padding-bottom: 30px;
	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;
		li {
			display: inline-block;
			color: #000000;
			margin-right: 2px;
			span {
				display: inline-block;
				margin-right: 3px;
				color: #F73C82;
				font-size: 15px;
				font-weight: 500;
			}
			a {
				display: inline-block;
				width: 30px;
				height: 30px;
				color: #F73C82;
				border-radius: 50%;
				background-color: transparent;
				text-align: center;
				line-height: 29px;
				font-size: 12.5px;
				border: 1px solid #eeeeee;
				&:hover {
					background-color: #F73C82;
					color: #ffffff;
					border-color: #F73C82;
					-webkit-box-shadow: #F73C82 0px 12px 24px -10px;
					box-shadow: #F73C82 0px 12px 24px -10px;
					-webkit-transform: translateY(-3px);
					transform: translateY(-3px);
				}
			}
		}
	}
}
.navbar-new{
	position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
	justify-content: space-between;
	.navbar-brand{
		display: inline-block;
		padding-top: .3125rem;
		padding-bottom: .3125rem;
		margin-right: 1rem;
		font-size: 1.25rem;
		line-height: inherit;
		white-space: nowrap;
	}
	.navbar-menu{
		align-items: stretch;
    display: flex;
	}
}
.under-construction .main-wrapper{
	max-width: 640px;
}

.text-inner-banner-one.career{
	padding: 70px 0 100px;
}

.mt-5{
	margin-top: 20px;
}

.apply-link{
	margin-top:20px;
	font-size:20px !important;
	color: #106ad8 !important;
	// font-weight: bold;
}

.single-help-box{
	.badge{
		font-size:16px;
		letter-spacing: 1px;
		margin-bottom: 20px;
	}
}

.badge-lg{
	font-size:16px !important;
		letter-spacing: 1px;
		margin-bottom: 10px;
}

.single-job{
	p{
		color: var(--text-color);
		padding:5px 0px;
	}
}

#get-quote{
	@media(min-width:767px){
		margin-top: -200px;
	}
}

#our-team{
	@media(min-width:767px){
		margin-top: -150px;
	}
}

.close_icon{
	position: absolute;
	right: 0;
	i{
		color: #fff;	
	}
}