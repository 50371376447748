	/*
  	Flaticon icon font: Flaticon
  	Creation date: 13/03/2019 14:58
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: inherit;
font-style: normal;
color: inherit;
line-height: initial;
}

.flaticon-right:before { content: "\f100"; }
.flaticon-play-button:before { content: "\f101"; }
.flaticon-play-button-1:before { content: "\f102"; }
.flaticon-next:before { content: "\f103"; }
.flaticon-next-1:before { content: "\f104"; }
.flaticon-back:before { content: "\f105"; }
.flaticon-back-1:before { content: "\f106"; }
.flaticon-tick:before { content: "\f107"; }
.flaticon-checked:before { content: "\f108"; }
.flaticon-close:before { content: "\f109"; }
.flaticon-cancel:before { content: "\f10a"; }
.flaticon-star:before { content: "\f10b"; }
.flaticon-star-1:before { content: "\f10c"; }
.flaticon-web:before { content: "\f10d"; }
.flaticon-value:before { content: "\f10e"; }
.flaticon-login:before { content: "\f10f"; }
.flaticon-dollar-symbol:before { content: "\f110"; }
.flaticon-left-quote:before { content: "\f111"; }
.flaticon-mic:before { content: "\f112"; }
.flaticon-user:before { content: "\f113"; }
.flaticon-play-button-2:before { content: "\f114"; }
.flaticon-menu:before { content: "\f115"; }
.flaticon-menu-1:before { content: "\f116"; }
.flaticon-setup:before { content: "\f117"; }
.flaticon-menu-2:before { content: "\f118"; }